import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import CustomInputError from "./CustomInputError";

function CustomTextarea(props) {
  const [labelVisibility, setLabelVisibility] = useState("none");
  const width = props.width || "100%";
  const resize = props.resize || "none";
  const height = props.height || "200px";

  useEffect(() => {
    if (props.value === "" && labelVisibility === "block") {
      setLabelVisibility("none");
    } else if (props.value !== "" && labelVisibility === "none") {
      setLabelVisibility("block");
    }
  }, [props.value]);

  return (
    <p>
        <textarea
          placeholder={props.text}
          name={props.name}
          id={props.id}
          value={props.value}
          style={{
            resize: resize,
            width: width,
            height: height
          }}
          className="w3-input w3-border-top w3-border-blue"
          onChange={props.onChange}
        />
      <label style={{display: labelVisibility}} htmlFor={props.id}>{props.text}</label>
      {props.touched && props.error ? <CustomInputError message={props.error}/> : null}
    </p>
  );
}

CustomTextarea.propTypes = {
  value: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  resize: PropTypes.string,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  name: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
};

export default CustomTextarea;
