import React from "react";
import PropTypes from "prop-types"

function CustomInputError(props) {

  return (
    <span className="w3-text-red w3-small margin-left-6">{props.message}</span>
  )
}

CustomInputError.propTypes = {
  message: PropTypes.string,
};

export default CustomInputError;