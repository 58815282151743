import React from "react";
import PropTypes from "prop-types";

function FetchError(props) {
  return (
    <div className="w3-container">
      <div className="w3-panel w3-pale-red w3-round w3-leftbar w3-border-red ">
        <h3>Error!</h3>
        {props.errorArray && props.errorArray.length > 0 ? props.errorArray.map((error, index) => <p key={index}>{error}</p>) :
          <p>{props.error}</p>}
      </div>
    </div>
  );
}

FetchError.propTypes = {
  error: PropTypes.string,
  errorArray: PropTypes.array,
};

export default FetchError;
