export const LIST_TYPE = {
  SHOPPINGLIST: "SHOPPINGLIST",
  WISHLIST: "WISHLIST"
};
export const WEATHER = {
  IMAGE : {
    200: "thunderstorm",
    201: "thunderstorm",
    202: "thunderstorm",
    210: "thunderstorm",
    211: "thunderstorm",
    212: "thunderstorm",
    221: "thunderstorm",
    230: "thunderstorm",
    231: "thunderstorm",
    232: "thunderstorm",
    300: "drizzle",
    301: "drizzle",
    302: "drizzle",
    310: "drizzle",
    311: "drizzle",
    312: "drizzle",
    313: "drizzle",
    314: "drizzle",
    321: "drizzle",
    500: "rain",
    501: "rain",
    502: "rain",
    503: "rain",
    504: "rain",
    511: "snow",
    520: "rain",
    521: "rain",
    522: "rain",
    531: "rain",
    600: "snow",
    601: "snow",
    602: "snow",
    611: "snow",
    612: "snow",
    613: "snow",
    615: "snow",
    616: "snow",
    620: "snow",
    621: "snow",
    622: "snow",
    701: "fog",
    711: "fog",
    721: "fog",
    731: "fog",
    741: "fog",
    751: "fog",
    761: "fog",
    762: "fog",
    771: "fog",
    781: "fog",
    800: "clear",
    801: "clouds",
    802: "clouds",
    803: "clouds",
    804: "clouds"
  },
  ICON : {
    200: "tstorms",
    201: "tstorms",
    202: "tstorms",
    210: "tstorms",
    211: "tstorms",
    212: "tstorms",
    221: "tstorms",
    230: "tstorms",
    231: "tstorms",
    232: "tstorms",
    300: "chancerain",
    301: "chancerain",
    302: "chancerain",
    310: "chancerain",
    311: "chancerain",
    312: "chancerain",
    313: "chancerain",
    314: "chancerain",
    321: "chancerain",
    500: "chancerain",
    501: "rain",
    502: "rain",
    503: "rain",
    504: "rain",
    511: "sleet",
    520: "rain",
    521: "rain",
    522: "chancerain",
    531: "rain",
    600: "chancesnow",
    601: "snow",
    602: "snow",
    611: "sleet",
    612: "chancesleet",
    613: "snow",
    615: "snow",
    616: "snow",
    620: "snow",
    621: "snow",
    622: "snow",
    701: "fog",
    711: "fog",
    721: "fog",
    731: "fog",
    741: "fog",
    751: "fog",
    761: "fog",
    762: "fog",
    771: "fog",
    781: "flurries",
    800: "clear",
    801: "mostlysunny",
    802: "mostlysunny",
    803: "mostlycloudy",
    804: "cloudy"
  }
};

export const WEEKDAYS = {
  LONG: ["Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag"],
  SHORT: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"]
};

export const TOAST_TYPE = {
  SUCCESS : "success",
  ERROR: "error",
  WARNING: "warning"
}

export const API_VERSION = "/v1";

export const CALENDAR_COLORS = ["blue", "indigo", "amber", "lime", "light-green", "deep-orange", "blue-gray"];

export const CALENDAR_SHARING_OPTIONS = {
  DISABLED: 0,
  PRIVATE: 2,
  SHARED: 1,
}