import React from "react";
import Profile from "./Profile";
import PropTypes from "prop-types";
import ProfileCalendar from "./ProfileCalendar";
import { useState } from "react";
import CustomFileInput from "../CustomFileInput";

export default function Accounts(props) {

    const [profilePicture] = useState("/static/img/img_avatar4.png")

  return (
    <div className="w3-row" style={{maxWidth: "800px", margin: "0 auto", display: "flex", alignItems: "center"}}>
      <div style={{maxWidth: "300px"}} className="w3-hide-small w3-col l3 m3 w3-margin-right w3-display-container">
        <img src={profilePicture} alt={"Profilbild"} style={{"width": "100%"}} className="w3-circle"/>
        <CustomFileInput 
          type={"file"} 
          id={"profilePictureUpload"} 
          onChange={() => {}} 
          hidden={true} 
          icon={"fas fa-upload w3-display-topright"}
          iconStyle = {{"cursor": "pointer", marginRight:"35px", marginTop: "15px"}}/>
      </div>

      <div style={{"maxWidth": "500px", margin:"0 auto"}} className="w3-col s12 l9 m8">
        <div className="w3-card-4 w3-round">
          <div className={"w3-display-container w3-hide-medium w3-hide-large"}>
            <img src={profilePicture} alt={"Profilbild"} style={{"width": "100%"}}/>
            <CustomFileInput 
              type={"file"} 
              id={"profilePictureUpload"} 
              onChange={() => {}} 
              hidden={true} 
              icon={"fas fa-upload w3-display-topright w3-margin"}
              style={{"cursor": "pointer"}}/>
          </div>
          <Profile globalDispatch={props.globalDispatch}/> 
        </div>
        <div className="w3-card-4 w3-round">
          <ProfileCalendar />
        </div>
      </div>
    </div>
  );
}

Accounts.propTypes = {
  globalDispatch: PropTypes.func.isRequired,
}