import React from "react";
import "./styles/index.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import "w3-css/w3.css";
import "weather-underground-icons"
import "./styles/weather-icons.min.css"
import "./styles/CustomCss.scss"
import "@fortawesome/fontawesome-free/css/all.css"
import {CookiesProvider} from "react-cookie";
import {createRoot} from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";

const container = document.getElementById("root");
const root = createRoot(container)
root.render(
  <CookiesProvider>
    <GoogleOAuthProvider clientId="799950244995-ahuvc7v685knvubg27qhe14rc3k8g740.apps.googleusercontent.com">
      <App/>
    </GoogleOAuthProvider>
  </CookiesProvider>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
