import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function Modal(props) {
  const styleContent = classNames({
    "w3-white": props.styleDefault,
    "w3-pale-yellow": props.styleWarning,
  });

  const styleHeader = classNames({
    "w3-blue-gray": props.styleDefault,
    "w3-yellow": props.styleWarning,
  });

  const styleButton = classNames({
    "w3-hover-yellow": props.styleWarning
  })

  return (
    <div id={props.id} className="w3-modal">
      <div className={"w3-modal-content " + styleContent}>
        <header className={"w3-container " + styleHeader}>
            <span onClick={() => document.getElementById(props.id).style.display = 'none'}
                  className={"w3-button w3-display-topright " + styleButton}>&times;</span>
          <h4>{props.title}</h4>
        </header>
        <div className="w3-container">
          {props.content}
        </div>
      </div>
    </div>
  );
}

export default Modal;

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.any.isRequired,
  styleDefault: PropTypes.bool,
  styleWarning: PropTypes.bool,
}