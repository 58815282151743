import {WEATHER, WEEKDAYS} from "../constants";

export const Actions = {
  CITIES_FETCH_RETURNED: "CITIES_FETCH_RETURNED",
  CITIES_FORECAST_RETURNED: "CITIES_FORECAST_RETURNED",
  CITY_DELETE: "CITY_DELETE",
  CITIES_ADD: "CITIES_ADD",
  CITY_RENAME: "CITY_RENAME"
}

export const init = () => {
  return {
    weatherCities: [],
  };
};

export const reducer = (state, action) => {
  switch (action.type) {
    case Actions.CITIES_FETCH_RETURNED:
      return {
        ...state,
        weatherCities: action.cities
      }
    case Actions.CITIES_ADD: {
      let weatherCities = [
        ...state.weatherCities,
        action.city
      ];
      return {
        ...state,
        weatherCities: weatherCities
      }
    }
    case Actions.CITY_DELETE: {
      let cities = [...state.weatherCities];
      return {
        ...state,
        weatherCities: cities.filter(city => city.url !== action.url)
      }
    }
    case Actions.CITY_RENAME: {
      let cities = [...state.weatherCities];
      let cityToUpdate = cities.find(city => city.url === action.data.url);
      cityToUpdate.name = action.data.name;
      return {
        ...state,
        weatherCities: cities
      }
    }
    case Actions.CITIES_FORECAST_RETURNED: {
      let cities = state.weatherCities
      // eslint-disable-next-line
      Object.values(action.forecastData).map(data => {
        let city = cities.find(city => city.url === data.city_id);
        let currentDate = new Date((data.current.dt) * 1000);
        let forecastList = [];
        for (let i = 0; i < 6; i++) {
          let date = new Date((data.forecast[i].dt) * 1000);
          let sunriseDate = new Date((data.forecast[i].sunrise) * 1000);
          let sunsetDate = new Date((data.forecast[i].sunset) * 1000);
          let forecastEntry = {
            weekday: WEEKDAYS.SHORT[date.getDay()],
            icon: WEATHER.ICON[data.forecast[i].weather[0].id],
            image: WEATHER.IMAGE[data.forecast[i].weather[0].id],
            description: data.forecast[i].weather[0].description,
            maxTemp: Math.ceil(data.forecast[i].temp.max),
            minTemp: Math.floor(data.forecast[i].temp.min),
            humidity: data.forecast[i].humidity,
            windSpeed: data.forecast[i].wind_speed,
            sunrise: sunriseDate.toLocaleTimeString([], {timeZone: data.timezone, hour: '2-digit', minute: '2-digit'}),
            sunset: sunsetDate.toLocaleTimeString([], {timeZone: data.timezone, hour: '2-digit', minute: '2-digit'}),
          };
          forecastList.push(forecastEntry);
        }
        city.forecast = forecastList;
        city.timeZone = data.timezone
        city.current = {
          description: data.current.weather[0].description,
          temp: data.current.temp,
          image: WEATHER.IMAGE[data.current.weather[0].id],
          time: currentDate.toLocaleTimeString([], {timeZone: data.timezone, hour: '2-digit', minute: '2-digit'}),
        }
      })
      return {
        ...state,
        weatherCities: cities
      }
    }
    default:
      return {...state}
  }
}