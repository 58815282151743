import {useEffect, useState} from "react";

/**
 * This combines useState with local storage to keep the states after page reload
 *
 * @param {String} localStorageKey the key for the local storage
 * @param {String} defaultValue needs to be a string, as firefox can only store strings to local storage
 */
const useStateWithLocalStorage = (localStorageKey, defaultValue) => {
  const [value, setValue] = useState(
    JSON.parse(localStorage.getItem(localStorageKey)) || defaultValue
  );

  useEffect(() => {
    const object = JSON.stringify(value);
    localStorage.setItem(localStorageKey, object);
  }, [value, localStorageKey]);

  return [value, setValue];
};

export default useStateWithLocalStorage;