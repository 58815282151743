import React, {Component} from "react";

class TimeDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: "",
      date: "",
      timePoints: false
    };
  }

  

  componentDidMount() {
    this.timerId = setInterval(() => this.blinkingClock(), 500);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  getDate() {
    const options = {
      weekday: "long",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }
    const locale = navigator.language || navigator.userLanguage;
    let date = new Date();
    return date.toLocaleDateString(locale, options)
  }

  getTime() {
    const locale = navigator.language || navigator.userLanguage;
    var date = new Date();
    var hours = date.toLocaleTimeString(locale, {hour: "2-digit"})
    var minutes = date.toLocaleTimeString(locale, {minute: "2-digit"})

    return (
      <React.Fragment>
        {hours.split(' ')[0]}
        <span id="timePoints">:</span>
        {minutes}
      </React.Fragment>
    );
  }

  blinkingClock() {
    var blink = document.getElementById("timePoints");
    if (blink != null) {
      if (!this.state.timePoints) {
        blink.style.opacity = "1";
        this.setState({ timePoints: true });
      } else {
        blink.style.opacity = "0";
        blink.style.transition = "opacity 0.3s linear";
        this.setState({ timePoints: false });
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="w3-row w3-center">
          <h2>{this.getDate()}</h2>
        </div>
        <div className="w3-row w3-center">
          <h1>{this.getTime()}</h1>
        </div>
      </React.Fragment>
    );
  }
}

export default TimeDate;
