import React from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

function Footer(props) {

  return (
    <div className="w3-container">
      <div className="w3-right w3-margin">
        <span style={{cursor: "pointer", textDecoration: 'underline'}} className="w3-margin-right"
              onClick={() => props.setCookieConsent(null)}>Cookie Management</span>
        {/*ToDo: remove app and solve again in App.js like before upgrade of react-router */}
        <Link to={"/app/dataPrivacy"}>Datenschutzerklärung</Link>
      </div>
    </div>
  );
}

export default Footer;

Footer.propTypes = {
  setCookieConsent: PropTypes.func.isRequired,
};