import React, {useEffect, useReducer, useState} from "react";
import useFetch from "../../hooks/UseFetch";
import FetchError from "../FetchError";
import AddUser from "./AddUser";
import UserList from "./UserList";
import {init, reducer} from "../../reducers/AdminReducer";


function Admin() {
  const [admin, setAdmin] = useState(false);
  const [error, setError] = useState([]);
  const [state, dispatch] = useReducer(reducer, null, init);
  const fetch = useFetch();

  useEffect(() => {
    fetch._get("/users/current/admin/", data => {
      setAdmin(data);
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //ToDo: Redesign
  //ToDo: show status of user (online or not)

  return (
    admin && <React.Fragment>

      <div className="w3-card-4 w3-blue-gray">
        <h2 className="w3-margin-left" style={{display: "ruby"}}>User Management</h2>
      </div>
      <div id={"userMgmt"}>
        <div className="w3-margin-bottom">
          <AddUser setError={setError} dispatch={dispatch}/>
        </div>
        {fetch.error && <FetchError error={fetch.error} errorArray={error}/>}
        <UserList dispatch={dispatch} state={state}/>
      </div>
    </React.Fragment>
  );
}

export default Admin;