import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import useFetch from "../../hooks/UseFetch";
import {Actions as Action} from "../../reducers/WeatherCityReducer";
import CustomIconButton from "../CustomIconButton";
import CustomInput from "../CustomInput";
import {useFormik} from "formik";

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = "Required";
  }
  if (values.name.length > 255) {
    errors.name = "Bitte kürzeren Namen wählen"
  }

  return errors;
}

function WeatherPanel(props) {
  const fetch = useFetch();
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const form = useFormik({
    initialValues: {
      name: name
    },
    validate,
    onSubmit: values => handleFormSubmit(values)
  });

  function handleFormSubmit(values) {
    fetch._patch(props.city.url, {name: values.name}, (data, status) => {
      setEditMode(false);
      if (status === 200) {
        setName(values.name);
        props.dispatch({
          type: Action.CITY_RENAME,
          data: data
        });

      }
    })
  }

  useEffect(() => {
    setName(props.city.name);
    setCountry(props.city.country)
  }, [props.city]);

  if (props.city.forecast === undefined) {
    return null;
  }

  let weatherToday = props.city.forecast[0];
  let image = props.city.current.image === "clear" && (props.city.current.time > weatherToday.sunset || props.city.current.time < weatherToday.sunrise) ? "night" : props.city.current.image

  function deleteCity() {
    fetch._delete(props.city.url, () => {
      props.dispatch({
        type: Action.CITY_DELETE,
        url: props.city.url
      })
    });
  }

  return (
    <div className="w3-col m6 l4 padding-8">
      <div className={"w3-card-4"}>
        <div className="w3-display-container w3-text-white">
          <img src={"/static/img/weatherPanel/" + image + ".jpg"} alt={props.city.current.image}
               width={"100%"}
               style={{maxHeight: "350px", objectFit: "cover", minHeight: "271px"}}/>
          <div className={"w3-display-hover w3-container w3-display-left"}>
            {!editMode ?
              <CustomIconButton altText={"edit"}
                                icon={"edit.png"}
                                onClick={() => setEditMode(true)}
                                round={true}/> :
              <CustomIconButton altText={"save"}
                                icon={"confirm.png"}
                                onClick={form.submitForm}
                                round={true}/>}

          </div>
          <div className={"w3-container w3-display-right w3-display-hover"}>
            {!editMode ?
              <CustomIconButton altText={"delete"}
                                icon={"delete.png"}
                                class={"w3-red w3-hover-red"}
                                onClick={deleteCity}
                                round={true}/> :
              <CustomIconButton altText={"cancel"}
                                icon={"cancel.png"}
                                class={"w3-hover-red w3-red "}
                                onClick={() => setEditMode(false)}
                                round={true}/>}
          </div>

          <div className="w3-display-topmiddle w3-container w3-margin-top w3-center" style={{whiteSpace: "nowrap"}}>
            {!editMode ? <React.Fragment>
                <div className={"w3-xlarge"}>{props.icon && <i className={props.icon} />} {name}{country&& ", "+country}</div>
                <div>{props.date.toLocaleDateString([], {
                  timeZone: props.city.timeZone,
                  hour: "2-digit",
                  minute: "2-digit",
                  day: "2-digit",
                  month: "long",
                  weekday: "short"
                })}</div>
                <div>{props.city.current.description}</div>
              </React.Fragment> :
              <CustomInput type={"text"} id={"name"} onChange={form.handleChange} value={form.values.name}/>}
          </div>

          <div className={"w3-hide-large w3-hide-medium"}>
            <div className="w3-display-middle w3-container" style={{whiteSpace: "nowrap"}}>
              <div className={"w3-center w3-xlarge"}>{props.city.current.temp}°C</div>
              <div>{weatherToday.maxTemp}°C / {weatherToday.minTemp}°C</div>
            </div>
            <div className={"w3-display-bottomleft w3-container w3-center"}>
              <div><i
                className={"wu wu-white wu-32 wu-" + props.city.forecast[0].icon}/>{props.city.forecast[0].weekday}
              </div>
              <div>{props.city.forecast[0].maxTemp}°C / {props.city.forecast[0].minTemp}°C</div>
            </div>
            <div className={"w3-display-bottommiddle w3-container w3-center"}>
              <div><i
                className={"wu wu-white wu-32 wu-" + props.city.forecast[1].icon}/>{props.city.forecast[1].weekday}
              </div>
              <div>{props.city.forecast[1].maxTemp}°C / {props.city.forecast[1].minTemp}°C</div>
            </div>
            <div className={"w3-display-bottomright w3-container w3-center"}>
              <div><i
                className={"wu wu-white wu-32 wu-" + props.city.forecast[2].icon}/>{props.city.forecast[2].weekday}
              </div>
              <div>{props.city.forecast[2].maxTemp}°C / {props.city.forecast[2].minTemp}°C</div>
            </div>
          </div>
          <div className={"w3-hide-small"}>
            <div className={"w3-display-middle"}>
              <div className={"w3-xxlarge w3-hide-medium w3-center"}>{props.city.current.temp}°C</div>
              <div className={"w3-xlarge w3-hide-large w3-center"}>{props.city.current.temp}°C</div>
              <div className={"w3-large w3-hide-medium"}>
                <div className={"w3-center"}><i className={"wi wi-thermometer"}/> {weatherToday.maxTemp}°C | <i
                  className={"wi wi-thermometer-exterior"}/> {weatherToday.minTemp}°C
                </div>
              </div>
              <div className={"w3-hide-large"}>
                <div className={"w3-center"}>
                  <i className={"wi wi-thermometer"}/> {weatherToday.maxTemp}°C | <i
                  className={"wi wi-thermometer-exterior"}/> {weatherToday.minTemp}°C
                </div>
              </div>
            </div>
            <div className="w3-display-bottomleft w3-margin">
              <div><i className={"wi wi-sunrise"}/> {weatherToday.sunrise}</div>
              <div><i className={"wi wi-sunset"}/> {weatherToday.sunset}</div>
            </div>
            <div className="w3-display-bottomright w3-margin w3-right-align">
              <div><i className={"wi wi-humidity"}/> {weatherToday.humidity}%</div>
              <div><i className={"wi wi-strong-wind"}/> {weatherToday.windSpeed}m/s</div>
            </div>
          </div>
        </div>
        <div className="w3-row-padding w3-border w3-hide-small">
          {props.city.forecast.map((forecast, index) => <PanelForecast forecast={forecast} key={index}/>)}
        </div>
      </div>
    </div>
  )
}

WeatherPanel.propTypes = {
  city: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  icon: PropTypes.string,
}

function PanelForecast(props) {

  return (
    <div className="w3-center w3-col m4 l2" style={{padding: "0 2px"}}>
      <div className={"w3-large w3-hide-medium"}>{props.forecast.weekday}</div>
      <div className={"w3-hide-large"}>{props.forecast.weekday}</div>
      <i className={"wu wu-black wu-64 display-xlarge wu-" + props.forecast.icon}/>
      <i className={"wu wu-black wu-32 hide-xlarge wu-" + props.forecast.icon}/>
      <div className="w3-small display-xlarge">{props.forecast.description}</div>
      <div className={"w3-small"}>{props.forecast.maxTemp + "°C | " + props.forecast.minTemp + "°C"}</div>
    </div>
  )
}

PanelForecast.propTypes = {
  forecast: PropTypes.object.isRequired,
}

export default WeatherPanel;