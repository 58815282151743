import React, { useContext, useEffect, useState } from "react";
import useFetch from "../../hooks/UseFetch";
import PropTypes from "prop-types";
import useStateWithLocalStorage from "../../hooks/UseStateWithLocalStorage";
import { useNavigate } from "react-router-dom";
import FetchLoading from "../FetchLoading"
import FetchError from "../FetchError";
import {GlobalReducerContext} from "../../contexts/GlobalReducerContext";
import {Actions as GlobalActions } from "../../reducers/GlobalReducer";
import { TOAST_TYPE } from "../../constants";


function Events(){
    const {state: globalState, dispatch: globalDispatch} = useContext(GlobalReducerContext);
    const navigate = useNavigate();
    const fetch = useFetch();
    const [storedEvents, setStoredEvents] = useStateWithLocalStorage("events", null);
    const [eventsDisplay, setEventsToDisplay] = useStateWithLocalStorage("displayOtherCalendars", "true")
    const [events, setEvents] = useState(storedEvents);
    const TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone
    const BROWSER_LANGUAGE = navigator.language || navigator.userLanguage;
    const currentDate = new Date();
    currentDate.setHours(0,0,0,0);
    let displayedTodayHeadline = false;
    let displayedFutureHeadline = false;
    //ToDo: Update after 15 minutes

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => fetchCalendarEvents(), [eventsDisplay])

    useEffect(() =>{
        setStoredEvents(events)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events]);

    return (
        <React.Fragment>
            <div>
                <div className="w3-row">
                    <span className="w3-col s5 m6 l9">&nbsp;</span>
                    <div className="w3-col s6 m5 l2">
                        {eventsDisplay === "true" ? <i className="fa-solid fa-user-group"/> : <i className="fa-solid fa-user" />}geteilte Kalender 
                    </div>
                    <div className="w3-col s1">
                        <label className={"switch"}>
                        <input type={"checkbox"} checked={eventsDisplay === "true"}
                                onChange={() => setEventsToDisplay(prevState => prevState === "true" ? "false": "true")}/>
                        <span className={"slider round"} />
                        </label>
                    </div>
                </div>
                
            {!events && fetch.loading ? <FetchLoading/> :
            !events && fetch.error !== "" ? <FetchError error={fetch.error}/> :
            events && events.length > 0 && Array.isArray(globalState.users)? events.map((event, index) =>{                
                if(index < 10){
                                        let eventOwner = globalState.users.find(user => user.url == event.user) || [];
                    const eventStart = new Date(event.start)
                    eventStart.setHours(0,0,0,0)
                    const isFirstToday = eventStart.toDateString() === currentDate.toDateString() && !displayedTodayHeadline;
                    const isFirstFeature = eventStart > currentDate && !displayedFutureHeadline;
                    if (isFirstToday){
                        displayedTodayHeadline = true;
                    }
                    if (isFirstFeature){
                        displayedFutureHeadline = true;
                    }
                    if(isFirstToday){
                        return (
                            <React.Fragment key={index}>
                                <div className="w3-xlarge">Heute:</div> 
                                <EventEntry key={index} title={event.title} start={event.start} end={event.end} timeZone={TIME_ZONE} language={BROWSER_LANGUAGE} location={event.location} color={eventOwner.color} />
                            </React.Fragment>)
                    }
                    if(isFirstFeature){
                        return (
                        <React.Fragment key={index}>
                            <div className="w3-xlarge">Demnächst:</div> 
                            <EventEntry key={index} title={event.title} start={event.start} end={event.end} timeZone={TIME_ZONE} language={BROWSER_LANGUAGE} location={event.location} color={eventOwner.color} />
                        </React.Fragment>)
                    }

                    return <EventEntry key={index} title={event.title} start={event.start} end={event.end} timeZone={TIME_ZONE} language={BROWSER_LANGUAGE} location={event.location} color={eventOwner.color} />
                }
            }): fetch.loading ? <FetchLoading /> : <div className={"w3-row w3-card w3-round-large w3-panel w3-leftbar w3-border-blue "}>keine Termine gefunden, <span style={{textDecorationLine: "underline", cursor: "pointer"}} onClick={() => navigate("/app/settings/accounts")}>bitte Kalender hinzufügen</span></div>}
            </div>
        </React.Fragment>
    );

    function fetchCalendarEvents() {
        fetch._get(`/v1/calendar/events/?all=${eventsDisplay}`, (data, status) => {
            if (status < 300) {
                setEvents(data);
            }else if(status >= 500){
                if (eventsDisplay){
                    setEventsToDisplay("false");
                    globalDispatch({
                        type: GlobalActions.DISPLAY_TOAST,
                        toast: {
                            type: TOAST_TYPE.ERROR,
                            message: "Fehler mit einem fremden Kalender. Lade nur die eigenen."
                        }

                    });
                } else {
                    fetch.setError("Daten können nicht aktualisiert werden");
                    globalDispatch({
                        type: GlobalActions.DISPLAY_TOAST,
                        toast: {
                            type: TOAST_TYPE.ERROR,
                            message: "Kalender konnte nicht aktualisiert werden."
                        }

                    });
                }
            }
        });
    }
}

function EventEntry(props) {

    const dateOptions = {
        month: "long",
        day: "2-digit",
        timeZone : props.timeZone,
    }
    
    const startTime = new Date(props.start);
    const endTime = new Date(props.end)
    let time, duration, allDayEvent;
    if (props.start.includes("T")){
        const timeOptions = {
            hour : '2-digit',
            minute : '2-digit',
            timeZone: props.timeZone
        }
        
        time = startTime.toLocaleString(props.language, timeOptions);
        const timeDifferenceMilliseconds = endTime - startTime;
        const hours = Math.floor(timeDifferenceMilliseconds/ (1000*60*60));
        const minutes = Math.floor((timeDifferenceMilliseconds / (1000*60))%60);
        const hoursText = hours > 1 ? `${hours} Stunden` : `${hours} Stunde`;
        const minutesText = minutes > 1? `${minutes} Minuten` : `${minutes} Minute`;

        allDayEvent = Math.round(timeDifferenceMilliseconds / (1000 * 60)) % (24*60) === 0;

        duration = hours > 0 && minutes > 0? `${hoursText} ${minutesText}` : 
            hours > 0 ? hoursText :
            minutes > 0 ? minutesText : ""

        if (allDayEvent && hours===24){
            duration = "ganztägig";
        }else if(allDayEvent && hours > 24){
            duration = "mehrtägig";
        }
    }
    let date = startTime.toLocaleString(props.language, dateOptions);


    return(
        <React.Fragment>
        <div className={`w3-row w3-card w3-round-large w3-panel w3-leftbar w3-border-${props.color}`}>
            <div className="w3-col s6 l3 w3-center w3-padding w3-hide-medium w3-hide-small">
                <div style={{fontWeight: "bold"}}>{!allDayEvent && time}</div>
                    <div className="w3-text-grey">{duration}</div>
            </div>            
            <div className="w3-col m12 l6 w3-padding">
                <div className="" style={{fontWeight: "bold"}}>{props.title}</div>
                {props.location && (
                <div>
                    <i className="fa-solid fa-location-dot" />
                    <span className="w3-text-grey"> {props.location}</span>
                </div>
                )}
                </div>
            <div className="w3-col l3 s6 w3-padding" style={{fontWeight: "bold"}}>{date}</div>
            <div className="w3-col s6 w3-center w3-padding w3-hide-large">
                <div style={{fontWeight: "bold"}}>{!allDayEvent && time}</div>
                    <div className="w3-text-grey">{duration}</div>
            </div>     
        </div>
        </React.Fragment>
    )
}

EventEntry.propTypes = {
    title : PropTypes.string.isRequired,
    start: PropTypes.string.isRequired,
    timeZone: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
    location: PropTypes.string,
    color: PropTypes.string,
}

export default Events;