export const Actions = {
  TOGGLE_BACK_TO_TOP: "TOGGLE_BACK_TO_TOP",
  USER_DELETE: "USER_DELETE",
  USER_FETCH_RETURNED: "USER_FETCH_RETURNED",
  DISPLAY_TOAST: "DISPLAY_TOAST",
  REMOVE_TOAST: "REMOVE_TOAST",
  DISPLAY_LOADING_SPINNER: "DISPLAY_LOADING_SPINNER",

};

export const init = () => {
  return {
    toTopVisible: false,
    users: [],
    toast: [],
    loadingSpinner: false,
  };
};

export const reducer = (state, action) => {
  switch (action.type) {
    case Actions.TOGGLE_BACK_TO_TOP: {
      return {
        ...state,
        toTopVisible: action.toTopVisible
      }
    }
    case Actions.USER_DELETE: {
      let users = [...state.users];
      return {
        ...state,
        users: users.filter(user => user.url !== action.url)
      }
    }
    case Actions.USER_FETCH_RETURNED: {
      return {
        ...state,
        users: action.users,
      }
    }
    case Actions.DISPLAY_TOAST:{
      return{
        ...state,
        toast: [...state.toast, action.toast]
      }
    }
    case Actions.REMOVE_TOAST:{
      let toasts = [...state.toast];
      return{
        ...state,
        toast: toasts.filter(toast => toast !== action.toast)
      }
    }
    case Actions.DISPLAY_LOADING_SPINNER: {
      return {
        ...state,
        loadingSpinner: !state.loadingSpinner
      }
    }
    default:
      return state;
  }
};
