import React, {useContext, useEffect, useState} from "react";
import useFetch from "../../hooks/UseFetch";
import {UserContext} from "../../contexts/UserContext";
import CustomIconButton from "../CustomIconButton";
import Modal from "../Modal";
import {useFormik} from "formik";
import CustomInput from "../CustomInput";
import CustomButton from "../CustomButton";
import PropTypes from "prop-types";
import {Actions as GlobalActions} from "../../reducers/GlobalReducer";

const validateAddress = values => {
    const errors = {};
    if (values.zip && values.zip.length > 5) {
        errors.zip = "Bitte gültige PLZ angeben"
    }
    if ((values.zip !== "" && (values.city === "" || values.street === "" || values.number === "")) ||
      (values.city !== "" && (values.zip === "" || values.street === "" || values.number === "")) ||
      (values.street !== "" && (values.zip === "" || values.city === "" || values.number === "")) ||
      (values.number !== "" && (values.zip === "" || values.city === "" || values.street === ""))) {
        errors.zip = "Alle Addressfelder müssen ausgefüllt werden"
        errors.city = "Alle Addressfelder müssen ausgefüllt werden"
        errors.street = "Alle Addressfelder müssen ausgefüllt werden"
        errors.number = "Alle Addressfelder müssen ausgefüllt werden"
    }

    return errors;
}

const validateUserData = values => {
    const errors = {};
    if (values.firstName === "") {
        errors.firstName = "Bitte Vornamen angeben"
    }
}

export default function Profile(props) {
    const [address, setAddress] = useState("");
    const [addressList, setAddressList] = useState([])
    const [selectedAddress, setSelectedAddress] = useState("");
    const [profileUrl, setProfileUrl] = useState("");
    const [showExistingAddresses, setShowExistingAddresses] = useState(true);
    const fetch = useFetch();
    const {currentUser} = useContext(UserContext);

    function handleAddressUpdate(values) {
        fetch._post("/addresses/", {
            zip: values.zip,
            city: values.city,
            street: values.street,
            number: values.number
        }, (data, status) => {
            if (status === 201) {
                setAddress(data);
                document.getElementById("addressModal").style.display = "none";
                setShowExistingAddresses(true)
                addressForm.resetForm()
                props.globalDispatch({
                    type: GlobalActions.DISPLAY_SUCCESS_TOAST,
                    successToastMessage: "Addresse gespeichert",
                });
                fetch._patch(profileUrl, {'address': data.url}, {});
            }
        });
    }

    const addressForm = useFormik({
        initialValues: {
            zip: "",
            city: "",
            street: "",
            number: "",
        },
        validate: values => validateAddress(values),
        onSubmit: values => handleAddressUpdate(values)
    })

    const profileForm = useFormik({
        initialValues: {
            firstName: currentUser.first_name,
            lastName: currentUser.last_name,
            email: currentUser.email,
        },
        validate: values => validateUserData(values),
    })

    useEffect(() => {
        profileForm.setValues({
            firstName: currentUser.first_name,
            lastName: currentUser.last_name,
            email: currentUser.email,
        }, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    useEffect(() => {
        fetch._get("/profiles/current/", (data, status) => {
            if (status === 200 && data['address'] !== null) {
                //fetch address
                setProfileUrl(data[0].url)
                fetch._get(data[0].address, (data, status) => {
                    if (status === 200) {
                        setAddress(data);
                    }
                });
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedAddress === "") {
            return;
        }
        fetch._patch(profileUrl, {"address": selectedAddress}, (data, status) => {
            if (status === 202) {
                props.globalDispatch({
                    type: GlobalActions.DISPLAY_SUCCESS_TOAST,
                    successToastMessage: "Addresse gespeichert",
                });
                fetch._get(data.address, (data, status) => {
                    if (status === 200) {
                        setAddress(data);
                    }
                });
                document.getElementById("addressModal").style.display = "none";
                setShowExistingAddresses(true)
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAddress])

    return (
      <React.Fragment>
        <div className={"w3-container"}>
            <div className={"w3-row"}>
                <h4 className={"w3-col s10"}>{currentUser.first_name} {currentUser.last_name}</h4>
                <CustomIconButton altText={"bearbeiten"} icon={"edit.png"}
                                onClick={() => {
                                    fetchAddresses();
                                    document.getElementById("addressModal").style.display = "block"
                                }} round={true}
                                class={"w3-col s2 "}
                                customStyle={{width: "fit-content", marginTop: "10px"}}/>
            </div>
            <div className={"w3-row"}>
                <i className={"w3-col s1 fas fa-building w3-margin-top"}/>
                <span className={"w3-col s11"}>
                    {address === "" || address['street'] === "" || address['number'] === "" || address['zip'] === "" || address['city'] === "" ?
                        "Keine Adresse hinterlegt. Allgemeine Einkaufsliste wird verwendet" :
                        address['street'] + " " + address['number']}<br/>
                        {address !== "" && address['zip'] + " " + address['city']}
                </span>
            </div>
            <div className={"w3-row"}>
                <i className={"w3-col s1 fas fa-envelope "}/>
                <span className={"w3-col s11"}>{currentUser.email}</span>
            </div>
        </div>
        <br />

        <Modal title={"Profil bearbeiten"} styleWarning={false} id={"addressModal"} styleDefault={true} content={
            showExistingAddresses ? listExistingAddresses() : getNewAddressForm()
        }
        />
      </React.Fragment>
    );

    function fetchAddresses() {
        fetch._get("/addresses/", (data, status) => {
            if (status === 200) {
                setAddressList(data.results)
            }
        })
    }

    function getNewAddressForm() {
        return (<form className={"w3-container"} onSubmit={(e) => {
            e.preventDefault();
            addressForm.submitForm();
        }}>
            <i className={"fa fa-2x fa-arrow-left"} onClick={() => setShowExistingAddresses(true)}
               style={{cursor: "pointer"}}/>
            {/*<span className={"w3-row-padding"}>
            <span className={"w3-col s6"}>
              <CustomInput type={"text"} id={"firstName"} onChange={form.handleChange} value={form.values.firstName}
                           touched={form.touched.firstName} error={form.errors.firstName} required={true}
                           autocomplete={"autocomplete"} name={"firstName"} text={"Vorname*"}/>
            </span>
            <span className={"w3-col s6"}>
              <CustomInput type={"text"} id={"lastName"} onChange={form.handleChange} value={form.values.lastName}
                           touched={form.touched.lastName} error={form.errors.lastName} required={false}
                           autocomplete={"autocomplete"} name={"lastName"} text={"Nachname"}/>
            </span>
          </span>
          <span className={"w3-row-padding"}>
            <span className={"w3-col"}>
            <CustomInput type={"email"} id={"email"} onChange={form.handleChange} value={form.values.email}
                         touched={form.touched.email} error={form.errors.email} required={true}
                         autocomplete={"autocomplete"} name={"email"} text={"E-Mail*"}/>
            </span>
          </span>*/}
            <span className={"w3-row-padding"}>
            <span className={"w3-col s9"}>
              <CustomInput type={"text"} id={"street"} onChange={addressForm.handleChange}
                           value={addressForm.values.street} touched={addressForm.touched.street}
                           error={addressForm.errors.street} required={false}
                           autocomplete={"autocomplete"}
                           name={"street"} text={"Straße"}/>
            </span>
            <span className={"w3-col s3"}>
              <CustomInput type={"text"} id={"number"} onChange={addressForm.handleChange}
                           value={addressForm.values.number}
                           touched={addressForm.touched.number} error={addressForm.errors.number} required={false}
                           autocomplete={"autocomplete"} name={"number"} text={"Hausnummer"}/>
            </span>
          </span>
            <span className={"w3-row-padding"}>
            <span className={"w3-col s3"}>
              <CustomInput type={"text"} id={"zip"} onChange={addressForm.handleChange} value={addressForm.values.zip}
                           touched={addressForm.touched.zip} error={addressForm.errors.zip} required={false}
                           autocomplete={"autocomplete"}
                           name={"zip"} text={"Plz"}/>
            </span>
            <span className={"w3-col s9"}>
              <CustomInput type={"text"} id={"city"} onChange={addressForm.handleChange} value={addressForm.values.city}
                           touched={addressForm.touched.city} error={addressForm.errors.city} required={false}
                           autocomplete={"autocomplete"} name={"city"} text={"Stadt"}/>
            </span>
          </span>
            <span className={"w3-row-padding"}>
            <CustomButton text={"Speichern"} disabled={fetch.loading} customClass={"w3-col"}/>
          </span>
        </form>);
    }

    function listExistingAddresses() {
        //ToDo: pagination for more then 16 addresses
        return (
          <React.StrictMode>
              {addressList.map(address => {
                  return (
                    <div className={"w3-col s6 w3-margin-top"}
                         onClick={() => setSelectedAddress(address.url)}
                         style={{cursor: "pointer"}} key={address.url}>
                        <input type={"radio"}
                               name={"address"}
                               value={address.url}
                               className={"w3-col s1 w3-margin-top"}
                        />
                        <span
                          className={"w3-col s11"}>{address.street + " " + address.number}<br/>{address.zip + " " + address.city}</span>
                    </div>)
              })}
              <CustomButton text={"Neue Adresse hinzufügen"} onClick={() => setShowExistingAddresses(false)}/>
          </React.StrictMode>
        );
    }
}

Profile.propTypes = {
    globalDispatch: PropTypes.func.isRequired,
}