export const Actions = {
  WEBCAMS_FETCH_RETURNED: "WEBCAMS_FETCH_RETURNED",
  WEBCAMS_ADD: "WEBCAMS_ADD",
  WEBCAMS_DELETE: "WEBCAMS_DELETE",
  WEBCAMS_UPDATE: "WEBCAMS_UPDATE",
};

export const init = () => {
  return {
    webcams: [],
  };
};

export const reducer = (state, action) => {
  switch (action.type) {
    case Actions.WEBCAMS_FETCH_RETURNED:
      return {
        ...state,
        webcams: action.webcams
      };

    case Actions.WEBCAMS_ADD: {
      let webcams = [
        ...state.webcams,
        action.webcam
      ];
      return {
        ...state,
        webcams: webcams
      };
    }
    case Actions.WEBCAMS_DELETE: {
      let webcams = [...state.webcams];
      return {
        ...state,
        webcams: webcams.filter(webcam => webcam.url !== action.url)
      }
    }
    case Actions.WEBCAMS_UPDATE: {
      let webcams = [...state.webcams];
      let webcamToUpdate = webcams.find(webcam => webcam.url === action.webcam.url);
      webcamToUpdate.name = action.webcam.name;
      webcamToUpdate.webcam_url = action.webcam.webcam_url;
      return {
        ...state,
        webcams: webcams
      }
    }
    default:
      return state;
  }
};
