import React, {useEffect, useState} from "react";
import useFetch from "../../hooks/UseFetch";
import {TOAST_TYPE, WEATHER, WEEKDAYS} from "../../constants";
import {Actions} from "../../reducers/GlobalReducer";
import PropTypes from "prop-types";
import useStateWithLocalStorage from "../../hooks/UseStateWithLocalStorage";
import Modal from "../Modal";
import "../../styles/w3-colors-flat.css"

function WeatherToday(props) {
  const fetch = useFetch();
  const [storedUserPosition, setStoredUserPosition] = useStateWithLocalStorage("userPosition", null);
  const [userPosition, setUserPosition] = useState(storedUserPosition || {city: "Standort wird geladen!"});
  const [temperature, setTemperature] = useState();
  const [dailyForecast, setDailyForecast] = useState([]);
  const [alerts, setAlerts] = useState([])
  const [currentAlert, setCurrentAlert] = useState(0);

  /**
   * get the current location of the user or set Neuhausen as default value in case of an error
   */
  useEffect(() => {
    const watchID = navigator.geolocation.watchPosition(location => {
      if (userPosition.coords === undefined || userPosition.coords.latitude !== location.coords.latitude || userPosition.coords.longitude !== location.coords.longitude) {
        setUserPosition(prevState => ({
          city: prevState.city,
          coords: {latitude: location.coords.latitude, longitude: location.coords.longitude}
        }));
      }
    }, (error) => {
      console.error(error.message);
      setUserPosition({coords: {latitude: 48.6819432, longitude: 9.2743744}, city: "Neuhausen auf den Fildern"});
    }, {maximumAge: 300000});

    return () => navigator.geolocation.clearWatch(watchID);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   */
  useEffect(() => {
    console.log("user position updated")
    fetchWeatherData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPosition.coords]);

  /**
   * update the stored location on location change
   */
  useEffect(() => {
    setStoredUserPosition(userPosition);
  }, [setStoredUserPosition, userPosition, userPosition.city, userPosition.coords]);

  /**
   * will fetch the weather every 10 minutes
   */
  useEffect(() => {
    fetchWeatherData();
    const weatherInterval = setInterval(() => {
      fetchWeatherData();
    }, 1000 * 60 * 10);
    return () => clearInterval(weatherInterval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * fetch the current weather and forecast
   */
  function fetchWeatherData() {
    if (userPosition.coords === undefined || userPosition.coords.latitude === undefined || userPosition.coords.longitude === undefined) {
      return;
    }
    fetch._get("/weather/current/city/?lat=" + userPosition.coords.latitude + "&lon=" + userPosition.coords.longitude, (data, status) => {
      if (status === 200) {
        setTemperature(data.current.temp + "°C");
        setUserPosition(prevState => ({
          city: data.name,
          coords: prevState.coords
        }));
        let forecastList = [];
        for (let i = 0; i < 4; i++) {
          let date = new Date(data.forecast[i].dt * 1000);
          let forecastEntry = {
            weekday: WEEKDAYS.SHORT[date.getDay()],
            icon: WEATHER.ICON[data.forecast[i].weather[0].id],
            description: data.forecast[i].weather[0].description,
            maxTemp: Math.ceil(data.forecast[i].temp.max),
            minTemp: Math.floor(data.forecast[i].temp.min),
          };
          forecastList.push(forecastEntry);
        }
        setDailyForecast(forecastList);
        if (data.alerts) {
          let newAlerts = [];
          data.alerts.map(alert =>{
            let entry ={};
            entry.name = alert.event;
            entry.description = alert.description;
            entry.start = (new Date(alert.start * 1000)).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
              day: '2-digit',
              month: '2-digit',
            });
            entry.end = (new Date(alert.end * 1000)).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
              day: '2-digit',
              month: '2-digit'
            });
            newAlerts.push(entry)
          })

          setAlerts(newAlerts)
        } else {
          setAlerts([]);
        }
      } else if (status !== 401) {
        props.globalDispatch({
          type: Actions.DISPLAY_TOAST,
          toast:{
            type: TOAST_TYPE.ERROR,
            message: "Wetterdaten können nicht abgerufen werden"
          }
        });
      }
    });
  }

  return (
    <div className="w3-card-4">
      <div className="w3-display-container w3-text-white w3-hide-small">
        <img
          src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fe_AgwhvsBHI%2Fmaxresdefault.jpg&f=1&nofb=1"
          alt="Lights" className="w3-hide-small" style={{width: "100%"}}/>
        {alerts.length > 0 && 
        <div className="w3-display-topmiddle w3-hide-medium">       
            <WeatherAlert alert={alerts[currentAlert]} totalAlerts={alerts.length} currentAlertNumber={currentAlert} setCurrentAlert={setCurrentAlert}/>
            </div>
        }
        <div className="w3-xlarge w3-display-bottomleft w3-padding">{userPosition.city} {temperature}</div>
      </div>
      {alerts.length > 0 &&
      <div className="w3-hide-large">
        <WeatherAlert alert={alerts[currentAlert]} totalAlerts={alerts.length} currentAlertNumber={currentAlert} setCurrentAlert={setCurrentAlert}/>
        </div>
      }
      <div className={"w3-hide-medium w3-hide-large"}>
        <div className="w3-large w3-padding">{userPosition.city} {temperature}</div>
      </div>
      <div className="w3-row">
        {dailyForecast.map((forecast, index) => {
          let border = index < 3 ? " w3-border-right" : ""
          return (
            <div className={"m3 s6 w3-col w3-center" + border} key={index}>
              <div className={"w3-large"}>{forecast.weekday}</div>
              <i className={"wu wu-black wu-64 wu-" + forecast.icon}>&nbsp;</i>
              <div>{forecast.maxTemp + "°C | " + forecast.minTemp + "°C"}</div>
            </div>)
        })}
      </div>
      {alerts.map((alert, index) =>{
        return (<WeatherAlertModal key={index} name={alert.name} description={alert.description} start={alert.start} end={alert.end} index={index}/>)
      })}
    </div>
  );
}

WeatherToday.propTypes = {
  globalDispatch: PropTypes.func.isRequired,
}


function WeatherAlert({alert, currentAlertNumber, totalAlerts, setCurrentAlert}){

  let dots = [];
  for (let i = 0; i<totalAlerts; i++){
    dots.push(
      <React.Fragment>
      <span key={i} className={"w3-badge warning-msg-border warning-msg-hover "+ (currentAlertNumber === i? "warning-msg-text" :"w3-transparent")} 
        style={{height:"13px",width:"13px",padding:"0", cursor:"pointer"}}
        onClick={() => setCurrentAlert(i)} />&nbsp;</React.Fragment>
    )
  }

  return(
    <React.Fragment>
      <div className="w3-content w3-display-container warning-msg ">
        <img src={"/static/img/icons/info.png"} className={"w3-display-topright"}
                style={{"width": "20px", "filter": "invert(20%)", "margin": "20px 20px 0 0", cursor:"pointer"}}
                onClick={() => document.getElementById(`warningModal${currentAlertNumber}`).style.display = "block"}/>
        <div className={"w3-panel "}>
          <h4>{alert.name}!</h4>
          {alert.start} Uhr - {alert.end} Uhr
        
        {totalAlerts>1 &&
        <div className="w3-center w3-large" style={{width:"100%"}}>
          <div className={"w3-display-bottomleft w3-hover-text-black w3-margin-left"} style={{cursor:"pointer"}} onClick={() => setCurrentAlert(prevAlert => prevAlert==0? totalAlerts-1 : prevAlert-1)}>&#10094;</div>
          <div className={"w3-display-bottomright w3-hover-text-black w3-margin-right"} style={{cursor:"pointer"}} onClick={() => setCurrentAlert(prevAlert => prevAlert < totalAlerts-1? prevAlert+1: 0)}>&#10095;</div>
          {dots}
        </div>}
        </div>
      </div>
    </React.Fragment>
  )
}

WeatherAlert.propTypes = {
  alert: PropTypes.object.isRequired,
  currentAlertNumber: PropTypes.number.isRequired,
  totalAlerts: PropTypes.number.isRequired,
  setCurrentAlert: PropTypes.func.isRequired,
}

function WeatherAlertModal({name, start, end, description, index}){

  return(
    <Modal title={name} styleWarning={true} id={`warningModal${index}`} content={
      <React.Fragment>
        <div>{start} Uhr - {end} Uhr</div>
        <div>{description}</div>
      </React.Fragment>
    }/>
  )

}

WeatherAlertModal.propTypes = {
  name: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
}

export default WeatherToday;
