import PropTypes from "prop-types";
import useFetch from "../../hooks/UseFetch";
import React, {useContext, useState} from "react";
import {UserContext} from "../../contexts/UserContext";
import {useFormik} from "formik";
import {Actions} from "../../reducers/WishlistReducer";
import {Actions as GlobalActions} from "../../reducers/GlobalReducer";
import CustomSelect from "../CustomSelect";
import CustomInput from "../CustomInput";
import CustomButton from "../CustomButton";
import {GlobalReducerContext} from "../../contexts/GlobalReducerContext";
import {TOAST_TYPE} from "../../constants";

const userOptions = (users) => {
  if (!Array.isArray(users) && typeof users[Symbol.iterator] !== 'function') {
    // users is not iterable
    return [];
  }
  if (users === null ||  users === []) {
    return [];
  }
  let userList = {};
  for (let user of users) {
    userList[user.url] = user.first_name;
  }
  return userList;
};

const validate = values => {
  const errors = {};
  if (!values.name || values.name.trim() === "") {
    errors.name = "Required"
  } else if (values.name.length > 255) {
    errors.name = "max. 255 Zeichen"
  }
  if (!values.user) {
    errors.user = "Bitte später versuchen. Etwas mit den Nutzerdaten ist falsch"
  }

  return errors;
};


function AddWishListEntry(props) {
  const fetch = useFetch();
  const {currentUser} = useContext(UserContext);
  const {state, dispatch} = useContext(GlobalReducerContext);
  const [labelCounter, setLabelCounter] = useState(0);

  const form = useFormik({
      initialValues: {
        amount: "1",
        name: "",
        user: currentUser.url,
        store: "",
        link: "",
      },
      validate,
      onSubmit: values => handleAddProductButtonClick(values)
    }
  );

  /**
   * handle click on add product button and send data to backend
   */
  function handleAddProductButtonClick(values) {
    fetch._post("/wishlist/", {
        "name": values.name,
        "user": values.user,
        "link": values.link,
      },
      (data, status) => {
        if (status < 300) {
          props.dispatch({
            type: Actions.WISHLIST_ADD,
            entry: data
          });
          dispatch({
            type: GlobalActions.DISPLAY_TOAST,
            toast:{
              type: TOAST_TYPE.SUCCESS,
              message: values.name + " added"
            }
          });
          form.resetForm();
          form.setSubmitting(false);
        } else {
          dispatch({
            type: GlobalActions.DISPLAY_TOAST,
            toast:{
              type: TOAST_TYPE.ERROR,
              message: "Fehler " + status + ": etwas ist schief gelaufen. Sollte der Fehler weiterhin bestehen kontaktieren Sie den Administrator"
            }

          })
        }
      }
    );
  }

  /**
   * function to increase the height of the input container if a label gets displayed
   */
  function increaseContainerHeight() {
    setLabelCounter(labelCounter => labelCounter + 1);
  }

  /**
   * function to decrease the height of the input container if a label gets invisible
   */
  function decreaseContainerHeight() {
    setLabelCounter(labelCounter => labelCounter > 0 ? labelCounter - 1 : 0);
  }

  return (
    <React.Fragment>
      <div className="w3-container w3-grey">
        <h3>Artikel hinzufügen</h3>
      </div>
      <form className={"w3-container addWishListArticle label_" + labelCounter} id="form"
            onSubmit={form.handleSubmit}>
        <CustomInput
          text="Artikel*"
          id="article"
          name="name"
          type="text"
          autocomplete="off"
          value={form.values.name}
          onChange={form.handleChange}
          increaseContainerHeight={increaseContainerHeight}
          decreaseContainerHeight={decreaseContainerHeight}
          error={form.errors.name}
          touched={form.touched.name}
        />
        <CustomInput type={"url"} id={"link"} onChange={form.handleChange} value={form.values.link} text={"Link"}
                     name={"link"} error={form.errors.link} touched={form.touched.link}
                     increaseContainerHeight={increaseContainerHeight}
                     decreaseContainerHeight={decreaseContainerHeight}/>
        <CustomSelect
          required={false}
          id="user"
          name="user"
          text={"Für"}
          defaultValue={currentUser.url}
          value={form.values.user}
          options={userOptions(state.users)}
          onChange={form.handleChange}
          increaseContainerHeight={increaseContainerHeight}
          decreaseContainerHeight={decreaseContainerHeight}
          error={form.errors.user}
          touched={form.touched.user}
        />
        <CustomButton text="Hinzufügen" disabled={fetch.loading}
                      customClass={fetch.loading ? "w3-grey" : null}/>
      </form>
    </React.Fragment>
  );
}

AddWishListEntry.propTypes = {
  dispatch: PropTypes.func,
};

export default AddWishListEntry;