import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import FilterListElement from "./FilterListElement";
import CustomInput from "./CustomInput";

function CustomInputWithFilterList(props) {
  const listName = props.id + "-list";
  const originalFilterList = props.filterList;
  const [filterList, setFilterList] = useState([]);
  const [filterListVisibility, setFilterListVisibility] = useState("none");
  const [preselectedFilterListElement, setPreselectedFilterListElement] = useState(-1);

  useEffect(() => {
    setFilterList({...originalFilterList})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalFilterList]);

  useEffect(() => {
    if (props.value === undefined) {
      return;
    }
    let filterValue = props.value.toUpperCase();
    const filteredList = originalFilterList.filter(item => item.name.toUpperCase().includes(filterValue));
    setFilterList(filteredList);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  function handleKeyPress(key){
    if(key.code == "ArrowDown"){
      setPreselectedFilterListElement(prevIndex => {
        let newIndex;
        if(prevIndex >= filterList.length-1){
          newIndex = 0;
        }else{
          newIndex = prevIndex + 1;
        }
        return newIndex;
      })
      return;
    }
    if(key.code === "ArrowUp"){
      setPreselectedFilterListElement(prevIndex => {
        let newIndex;
        if(prevIndex <= 0){
          newIndex = filterList.length-1;
        }else{
          newIndex = prevIndex -1;
        }
        return newIndex;
      })
      return;
    }
    if(key.code === "Enter"){
      if(preselectedFilterListElement >= 0){
        props.setValue(`${filterList[preselectedFilterListElement].name} `);
        ((document.getElementById(listName)).getElementsByTagName('li'))[preselectedFilterListElement].style.display = "none";
        setPreselectedFilterListElement(-1)
      }
      return;
    }
    if(key.code ==="Backspace"){
      setPreselectedFilterListElement(0);
    }
  }

  return (
    <div onBlur={() => setFilterListVisibility("none")} onFocus={() => setFilterListVisibility("block")} onKeyDownCapture={(e) => handleKeyPress(e)}>
      <CustomInput
        inputRef = {props.inputRef}
        type={props.type}
        text={props.text}
        required={props.required}
        id={props.id}
        name={props.name}
        autocomplete={props.autocomplete}
        value={props.value}
        onChange={props.onChange}
        increaseContainerHeight={props.increaseContainerHeight}
        decreaseContainerHeight={props.decreaseContainerHeight}
        error={props.error}
        touched={props.touched}
      />
      <ul className="w3-ul w3-margin-top" id={listName} style={{display: filterListVisibility}}>
        {Object.keys(filterList).map((key) =>
          <FilterListElement key={key} value={filterList[key].name} displayText={filterList[key].name}
                             setValue={props.setValue} activeElement={preselectedFilterListElement} index={Number(key)}/>
        )}

      </ul>
    </div>
  );

}

CustomInputWithFilterList.propTypes = {
  autocomplete: PropTypes.string,
  type: PropTypes.string.isRequired,
  text: PropTypes.string,
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  filterList: PropTypes.array.isRequired,
  hiddenValue: PropTypes.string,
  filterClick: PropTypes.func,
  setValue: PropTypes.func.isRequired,
  decreaseContainerHeight: PropTypes.func,
  increaseContainerHeight: PropTypes.func,
  error: PropTypes.string,
  touched: PropTypes.bool,
  name: PropTypes.string,
  inputRef: PropTypes.func,
};

export default CustomInputWithFilterList;
