import React, {useContext} from "react";

import PropTypes from "prop-types";
import useFetch from "../../hooks/UseFetch";
import {Actions} from "../../reducers/AdminReducer";
import {UserContext} from "../../contexts/UserContext";
import CustomIconButton from "../CustomIconButton";
import {GlobalReducerContext} from "../../contexts/GlobalReducerContext";
import {Actions as GlobalActions} from "../../reducers/GlobalReducer";
import {TOAST_TYPE} from "../../constants";

function UserListItem(props) {
  const fetch = useFetch();
  const {currentUser} = useContext(UserContext);
  const {dispatch} = useContext(GlobalReducerContext);

  function changeUserState(userStatus) {
    if (props.user.url === currentUser.url) {
      if (!window.confirm("Eigenen Nutzer wirklich deaktivieren? Zugriff geht dabei verloren!")) {
        return null;
      }
    }
    fetch._patch(props.user.url + "change_user_active_status/", {'is_active': userStatus}, (data, status) => {
      if (status === 200) {
        props.dispatch({
          type: Actions.USER_UPDATED,
          url: props.user.url,
          is_active: userStatus,
        })
      }
    })
  }

  function resendRegistrationMail() {
    fetch._post("/registration/resendInvitation/", {'email': props.user.email}, (data, status) => {
      if (status === 201) {
        dispatch({
          type: GlobalActions.DISPLAY_TOAST,
          toast: {
            type: TOAST_TYPE.SUCCESS,
            message: "E-Mail erneut versendet"
          }
        })
      } else {
        dispatch({
          type: GlobalActions.DISPLAY_TOAST,
          toast: {
            type: TOAST_TYPE.ERROR,
            message: "E-Mail konnte nicht versendet werden, " + data.error
          }
        })
      }
    })
  }

  return (
    <React.Fragment>
      <li className="w3-row" style={{display: "flex", alignItems: "center", flexWrap: "wrap"}}>
        <img className="w3-col m1 w3-circle w3-hide-small" src="/static/img/img_avatar4.png"
             alt="Profilbild"/>
        <div className="w3-col m10 w3-container">
          <span className="w3-large">{props.user.first_name} {props.user.last_name}</span><br/>
          <div style={{display: "flex"}}>
            <div><i className={"fa fa-envelope"} style={{paddingTop: "5px"}}/></div>
            <div className={"w3-margin-left"}>{props.user.email}</div>
          </div>
          {props.user.address && <div style={{display: "flex"}}>
            <div><i className={"fa fa-address-card w3-padding-top"}/></div>
            <div className={"w3-margin-left"}>
              {props.user.address.street} {props.user.address.number}<br/>
              {props.user.address.zip} {props.user.address.city}
            </div>
          </div>}
        </div>
        <div className={"w3-col m1"}>
          {props.registeredUser ?
            <label className={"switch"}>
              <input type={"checkbox"} defaultChecked={props.user.is_active}
                     onClick={() => changeUserState(!props.user.is_active)}/>
              <span className={"slider round"}/>
            </label>
            :
            <CustomIconButton altText={"resend"} icon={"resend_mail.png"} round={true}
                              customStyle={{width: "40px", height: "40px"}} class={"w3-center"}
                              onClick={resendRegistrationMail}/>}
        </div>
      </li>
    </React.Fragment>
  );
}

UserListItem.propTypes = {
  user: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  registeredUser: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

export default UserListItem;