/* eslint-disable react-hooks/exhaustive-deps */
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'
import momentPlugin from '@fullcalendar/moment'
import enLocale from '@fullcalendar/core/locales/en-gb'
import deLocale from '@fullcalendar/core/locales/de'
import interactionPlugin from '@fullcalendar/interaction'
import React, { useEffect, useReducer, useRef, useState } from "react";
import SideBar from "./SideBar";
import useStateWithLocalStorage from "../../hooks/UseStateWithLocalStorage"
import useFetch from "../../hooks/UseFetch"
import { Actions, init, reducer } from "../../reducers/CalendarReducer";
import {goToDayView, calendarView, updateWeekTextNumber, createEvent } from "./calendarUtils";
import { CALENDAR_COLORS } from "../../constants";

/**
 * ToDo:
 * 3. make calendar grey while updating events
 * 4. Show Event Details when clicking on it 
 */

function Calendar() {
    const [defaultCalendarView, setDefaultCalendarView] = useStateWithLocalStorage("defaultCalendarView", calendarView.MONTH);
    const [state, dispatch] = useReducer(reducer, null, init);
    const fetch = useFetch();
    const [calendarData, setCalendarData] = useState({
        fetchData: true,
        events: [],
        userEventIds: {},
        userUpdated: false,
        requestedDate: (new Date()).toISOString().split('T')[0],
    });
    const [loading, setLoading] = useState(false);
    const calendarRef = useRef(null);
    const setUserUpdated = (newValue) => {
        setCalendarData(prevData => ({...prevData, userUpdated:newValue}))
    }

    function handleViewChange(viewInfo) {
        setDefaultCalendarView(viewInfo.view.type);
        if (viewInfo.view.type === calendarView.WEEK){            
            updateWeekTextNumber(viewInfo);

        }
        const startDate = viewInfo.start.toISOString().split('T')[0];
        if(startDate !== calendarData.requestedDate){
            setCalendarData(prevData => ({...prevData, requestedDate: startDate, fetchData: true}));
            fetchAllEvents();
        }
    }    

    function handleDateClick(viewInfo){
        goToDayView(viewInfo);
    } 

    function handleEventsChange(){
        const userEventList = [];
        const newUserEventIds = {...calendarData.userEventIds};
        for (const user of state.availableCalendars){
            //User has no calendar and can be skipped. Just to avoid NPE. Should never exist
            if(!user.calendars){
                continue;
            }
            if(user.active){
                addEventsForUserToFullCalendar(user, userEventList, newUserEventIds);
            } else {
                removeEventsForUserFromFullCalendar(user.url);
            }
        }
        setCalendarData(prevData => ({...prevData, events: userEventList, userEventIds: newUserEventIds}));
    }

    function removeEventsForUserFromFullCalendar(userUrl) {
        const userEventIds = {...calendarData.userEventIds};
        const calendarAPI = calendarRef.current.getApi();
        if (userEventIds[userUrl]) {
            const eventsToRemove = userEventIds[userUrl];

            for (const eventId of eventsToRemove) {
                const eventToRemove = calendarAPI.getEventById(eventId);
                if (eventToRemove) {
                    eventToRemove.remove();
                }
            }

            delete userEventIds[userUrl];
        }
        setCalendarData(prevData => ({...prevData, userEventIds: userEventIds}));
    }

    function addEventsForUserToFullCalendar(user, userEventList, newUserEventIds) {
        user.calendars.map((calendar) => {
            for (const event of calendar.events) {
                const eventObject = createEvent(user, event);

                userEventList.push(eventObject);
                if (!newUserEventIds[user.url]) {
                    newUserEventIds[user.url] = [eventObject.id];
                } else {
                    newUserEventIds[user.url].push(eventObject.id);
                }
            }
        });
    }

    function fetchAllEvents() {
        if (calendarData.fetchData && state.availableCalendars.length > 0) {
            setLoading(true);
            setCalendarData(prevState => ({ ...prevState, fetchData: false }));
            
            const fetchPromises = [];
            for (const user of state.availableCalendars) {
                if (!user.active) {
                    continue;
                }
                fetchPromises.push(fetchAndAddEventsForUser(user));
            }
            Promise.allSettled(fetchPromises)
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    async function fetchEventsForCalendar(calendar){
        return new Promise((resolve, reject) => {
            fetch._get(`${calendar.url}events/?requested_date=${calendarData.requestedDate}`, (data, status)=>{
                if (status === 200){
                    resolve({calendar, data});
                } else {
                    reject(new Error(`Failed to fetch events for ${calendar.url}`));
                }
            })
        })
    }

    async function fetchAndAddEventsForUser(user) {
        for(const calendar of user.calendars){
            try{
                const {calendar: updatedCalendar, data} = await fetchEventsForCalendar(calendar);
                dispatch({
                    type: Actions.ADD_EVENTS_TO_CALENDAR,
                    url: updatedCalendar.url,
                    events: data,
                });
            } catch (error){
                console.error(error);
            }
        }
    }
    
    useEffect(() => {
        fetch._get("/calendar/user/", (userData, status)=>{
            if (status == 200){
                const userCalendars = userData.map((user, index) => ({
                    "name": user.first_name,
                    "active": true, //move to local storage
                    "color": CALENDAR_COLORS[index],
                    "url": user.url,
                    "calendars" : user.visible_calendars,
                }));
                dispatch({
                    type: Actions.AVAILABLE_CALENDARS,
                    availableCalendars: userCalendars,
                });
            }
        });
    },[]);

    useEffect(() => {
        fetchAllEvents();        
    }, [state.availableCalendars]);

    useEffect(() => {
        if(!loading){
            handleEventsChange();
            if(calendarData.userUpdated){
                setUserUpdated(false);
            }
        }
    }, [loading, calendarData.userUpdated])
    
    return(
        <div className="w3-container">
            <React.Fragment>
                <div className="w3-col w3-hide-small m2">&nbsp;
                    <SideBar calendars={state.availableCalendars} state={state} dispatch={dispatch} setUserUpdated={setUserUpdated} fetchEventsForUser={fetchAndAddEventsForUser} removeEventsForUser={removeEventsForUserFromFullCalendar} />
                </div>
                <div className="w3-hide-medium w3-hide-large w3-round w3-margin-top w3-center w3-light-grey w3-xlarge w3-card">
                    Gerät zu klein, bitte Handy kippen oder größeres Display verwenden
                </div>
                <div className="w3-col m10 w3-hide-small">
                    <FullCalendar
                        ref={calendarRef}
                        plugins={[ dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin, momentPlugin ]}
                        headerToolbar={{
                            left: 'prev next today',
                            center: 'title',
                            right: 'dayGridMonth timeGridWeek timeGridDay listWeek'
                        }}
                        nowIndicator={true}
                        initialView={defaultCalendarView}
                        locales= {[enLocale, deLocale]}
                        locale = {deLocale} // for later change https://fullcalendar.io/docs/locale
                        datesSet={handleViewChange}
                        dateClick={handleDateClick}
                        events={calendarData.events}
                        views={{
                            week:{
                                titleFormat:{
                                    // '{{DD.} MMMM} YYYY W'
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric',
                                },
                            }}
                        }
                    />
                </div>
            </React.Fragment>
        </div>
            
    )
}

export default Calendar;