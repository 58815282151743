import React from "react";
import "../styles/FetchLoading.css"

function FetchLoading() {

  return (
    <div className="w3-container">
      <div className="w3-center w3-panel w3-pale-blue w3-leftbar w3-border-blue w3-round">
        <h2>Loading data</h2>
        <div className="loading-icon">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>);
}

export default FetchLoading;
