// eslint-disable-next-line no-unused-vars
import React, {useEffect} from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line no-unused-vars
import {Actions} from "../reducers/GlobalReducer";
import {TOAST_TYPE} from "../constants";

function Toast(props) {

  useEffect(() => {
    const timer = setTimeout(() => {
      props.dispatch({
        type: Actions.REMOVE_TOAST,
        toast: props.toast,
      });
    }, 2000);
    return () => clearTimeout(timer);
  });

  const error = "w3-pale-red  w3-border-red"
  const success = "w3-pale-green w3-border-green"
  const warning = "w3-pale-yellow w3-leftbar w3-border-yellow"
  let style = "";

  switch(props.toast.type){
    case TOAST_TYPE.ERROR:
      style = error;
      break;
    case TOAST_TYPE.SUCCESS:
      style = success;
      break;
    case TOAST_TYPE.WARNING:
      style = warning;
      break
    default:
      break;
  }

  return (
    <div>
      <div className={"w3-center w3-panel w3-leftbar w3-round "+style} style={{display:"inline-block", marginBottom:"0px"}}>
        <p>{props.toast.message}</p>
      </div>
    </div>
  )
}

Toast.propTypes = {
  toast: PropTypes.object,
  dispatch: PropTypes.func,
};

export default React.memo(Toast);