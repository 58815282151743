import CustomIconButton from "./CustomIconButton";
import React from "react";

function BackToTop(){
  return(
    <div className="w3-display-bottomright w3-container" style={{position:"fixed"}}>
      <CustomIconButton altText={"up"} icon={"arrow_up.png"} round={true} onClick={()=> window.scrollTo(0,0)}/>
    </div>
  )
}

export default BackToTop;