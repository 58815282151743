import React, {useContext} from "react";
import CustomInput from "../CustomInput";
import CustomButton from "../CustomButton";
import useFetch from "../../hooks/UseFetch";
import {Actions} from "../../reducers/WebcamReducers";
import FetchError from "../FetchError";
import {Actions as GlobalActions} from "../../reducers/GlobalReducer";
import PropTypes from "prop-types";
import {useFormik} from "formik";
import {GlobalReducerContext} from "../../contexts/GlobalReducerContext";
import {TOAST_TYPE} from "../../constants";

const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.name = "Required";
  } else if (values.name.length > 255) {
    errors.name = "max. 255 Zeichen";
  }
  if (!values.url) {
    errors.url = "Required";
  } else if (values.url.length > 200) {
    errors.url = "max. 200 Zeichen";
  }

  return errors;
};

function AddWebcam(props) {
  const fetch = useFetch();
  const {dispatch} = useContext(GlobalReducerContext);

  const form = useFormik({
    initialValues: {
      url: "",
      name: "",
    },
    validate,
    onSubmit: values => handleWebcamFormSubmit(values)
  });

  function handleWebcamFormSubmit(values) {
    fetch._post("/webcams/", {"webcam_url": values.url, "name": values.name}, (data) => {
      props.dispatch({
        type: Actions.WEBCAMS_ADD,
        webcam: data,
      });
      dispatch({
        type: GlobalActions.DISPLAY_TOAST,
        toast:{
          type: TOAST_TYPE.SUCCESS,
          message: values.name + " hinzugefügt!"
        }
      });
      form.setSubmitting(false);
      form.resetForm();
      props.setShowAddWebcam(prevState => !prevState);
    });
  }

  return (
    <div className="w3-card">
      <div className={"w3-container"}>
        <h3>Webcam hinzufügen</h3><hr />
      </div>

      <form className="w3-container"
            onSubmit={form.handleSubmit}>
        <CustomInput text="Name" id="name" type="text" name="name" value={form.values.name}
                     onChange={form.handleChange} error={form.errors.name} touched={form.touched.name}/>
        <CustomInput text="URL" id="url" type="url" name="url" value={form.values.url}
                     onChange={form.handleChange} error={form.errors.url} touched={form.touched.url}/>
        <CustomButton text="Hinzufügen" disabled={fetch.loading}
                      customClass={fetch.loading ? "w3-grey" : null}/>
      </form>
      {fetch.error && <FetchError error={fetch.error}/>}
    </div>
  );
}

AddWebcam.propTypes = {
  dispatch: PropTypes.func.isRequired,
  setShowAddWebcam: PropTypes.func.isRequired,
};

export default AddWebcam;
