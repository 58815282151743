import React, {useEffect, useState} from "react";
import CustomIconButton from "../CustomIconButton";
import PropTypes from "prop-types";
import useFetch from "../../hooks/UseFetch";
import {Actions} from "../../reducers/WebcamReducers";
import CustomInput from "../CustomInput";
import FetchLoading from "../FetchLoading";
import FetchError from "../FetchError";

//ToDo: pagination
function Webcam(props) {
  const fetch = useFetch();
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [editMode, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => setName(props.name), [props.name]);
  useEffect(() => {
    setUrl(props.webcam_url);
    setLoading(true);
    setError("");
    setEdit(false);
  }, [props.webcam_url]);

  function handleDeleteButton() {
    fetch._delete(props.url, () => {
        props.dispatch({
          type: Actions.WEBCAMS_DELETE,
          url: props.url
        });
      }
    );
  }

  function handleSaveButton() {
    fetch._update(props.url, {"name": name, "webcam_url": url}, (data) => {
      props.dispatch({
        type: Actions.WEBCAMS_UPDATE,
        webcam: data
      });
      setError(false);
      setEdit(false);
    });
  }

  function handleImageError() {
    setLoading(false);
    setError(true);
  }

  return (
    <div className={"w3-col l4 m6 w3-padding"}>
      <div className="w3-card-4">
        <div className="w3-display-container w3-center">
          {loading && <FetchLoading/>}
          {error && <FetchError error={"Webcam konnte nicht geladen werden. Bitte die URL überprüfen"}/>}
          <img className={"w3-image " + (editMode ? "w3-greyscale " : "") + (loading ? "w3-hide" : "")} src={url}
               alt={name}
               onLoad={() => setLoading(false)} onError={handleImageError}/>
          {editMode && <div className="w3-display-bottommiddle" style={{width: "80%"}}>
            <CustomInput type={"text"}
                         id={"url"}
                         onChange={(e) => setUrl(e.target.value)}
                         value={url}/>
          </div>}
          <div className="w3-display-left w3-container w3-display-hover">
            {editMode ? <CustomIconButton altText={"save"}
                                          round={true}
                                          icon={"confirm.png"}
                                          class={"w3-green"}
                                          onClick={handleSaveButton}/> :
              <CustomIconButton icon="edit.png"
                                round={true}
                                altText="edit"
                                class={"w3-hover-grey"}
                                onClick={() => {
                                  setEdit(true);
                                }}/>}
          </div>
          <div className="w3-display-right w3-container w3-display-hover">
            {editMode ? <CustomIconButton altText={"cancel"}
                                          round={true}
                                          icon={"cancel.png"}
                                          class={"w3-red"}
                                          onClick={() => {
                                            setEdit(false);
                                            setUrl(props.webcam_url);
                                            setName(props.name);
                                          }}/> :
              <CustomIconButton icon="delete.png"
                                round={true}
                                altText="delete"
                                class={"w3-hover-grey w3-red"}
                                onClick={handleDeleteButton}/>}
          </div>
        </div>
        <div className="w3-container w3-center">
          {!editMode ? <p>{name}</p> :
            <CustomInput type="text" id="name" onChange={(e) => setName(e.target.value)} value={name}/>}
        </div>

      </div>
    </div>
  );
}

Webcam.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  webcam_url: PropTypes.string.isRequired
};

export default Webcam;
