export const Actions = {
  SHOPPINGLIST_FETCH_RETURNED: "SHOPPINGLIST_FETCH_RETURNED",
  SHOPPINGLIST_ADD: "SHOPPINGLIST_ADD",
  SHOPPINGLIST_DELETE: "SHOPPINGLIST_DELETE",
  SHOPPINGLIST_UPDATE: "SHOPPINGLIST_UPDATE",
  SHOPPINGLIST_BUY: "SHOPPINGLIST_BUY",
  SHOPPINGLIST_FILTER: "SHOPPINGLIST_FILTER",
  STORES_FETCH_RETURNED: "STORES_FETCH_RETURNED",
  STORES_UPDATE_SELECT: "STORES_UPDATE_SELECT",
  CATEGORIES_FETCH_RETURNED: "CATEGORIES_FETCH_RETURNED",
  CATEGORIES_ADD: "CATEGORIES_ADD",
};

export const init = () => {
  return {
    stores: [],
    entries: [],
    categories: [],
  };
};

function sortListByCategoryAndBought(list){
  return list.sort((a,b) => {
    if(a.is_bought && !b.is_bought){
      return 1;
    } else if(!a.is_bought && b.is_bought){
      return -1;
    } else{
      if (a.category < b.category){
        return -1;
      } else if(a.category > b.category){
        return 1;
      } else{
        return 0;
      }
    }
  });
}

export const reducer = (state, action) => {
  switch (action.type) {
    case Actions.SHOPPINGLIST_FETCH_RETURNED:
      return {
        ...state,
        entries: action.entries
      };
    case Actions.SHOPPINGLIST_ADD: {
      let entries = [
        action.entry,
        ...state.entries,
      ];
      return {
        ...state,
        entries: sortListByCategoryAndBought(entries)
      };
    }
    case Actions.SHOPPINGLIST_DELETE: {
      let entries = [...state.entries];
      return {
        ...state,
        entries: entries.filter(entry => entry.url !== action.url)
      }
    }
    case Actions.SHOPPINGLIST_BUY: {
      let entries = [...state.entries];
      let entryToUpdate = entries.find(entry => entry.url === action.url);
      entryToUpdate['is_bought'] = action.bought;
      return {
        ...state,
        entries: sortListByCategoryAndBought(entries)
      }
    }
    case Actions.SHOPPINGLIST_UPDATE: {
      let entries = [...state.entries];
      let entryToUpdate = entries.find(entry => entry.url === action.url);
      entryToUpdate['amount'] = action.entry.amount;
      entryToUpdate['name'] = action.entry.name;
      entryToUpdate['user'] = action.entry.user;
      entryToUpdate['store'] = action.entry.store;
      entryToUpdate['is_bought'] = action.entry.is_bought;

      return {
        ...state,
        entries: sortListByCategoryAndBought(entries)
      }
    }
    case Actions.STORES_FETCH_RETURNED: {
      return {
        ...state,
        stores: action.stores
      }
    }
    case Actions.STORES_UPDATE_SELECT: {
      let stores = [...state.stores];
      stores[action.index].selected = action.checked;
      return {
        ...state,
        stores: stores,
      }
    }
    case Actions.CATEGORIES_FETCH_RETURNED: {
      return {
        ...state,
        categories: action.categories
      }
    }
    case Actions.CATEGORIES_ADD:{
      let categories = [
        ...state.categories, action.category
      ];
      return{
        ...state,
        categories: categories
      }
    }
    default:
      return state

  }
};