import React, {useEffect} from "react";
import UserListItem from "./UserListItem";
import useFetch from "../../hooks/UseFetch";
import {Actions} from "../../reducers/AdminReducer";
import PropTypes from "prop-types";

function UserList(props) {
  const fetch = useFetch();

  useEffect(() => {
    fetch._get("/users/fullDetails/", (data, status) => {
      if (status === 200) {
        props.dispatch({
          type: Actions.USER_FULL_DETAILS_RETURNED,
          users: data,
        });
      }
    });
    fetch._get("/users/unregistered/", (data, status) => {
      if (status === 200) {
        props.dispatch({
          type: Actions.UNREGISTERED_USERS_RETURNED,
          users: data,
        });
      }
    });
    fetch._get("/profiles/all/", (profileData, status) => {
      if (status === 200) {
        fetch._get("/addresses/", (addressData, status) => {
          if (status === 200) {
            props.dispatch({
              type: Actions.USER_PROFILE_ADDRESS_RETURNED,
              profiles: profileData,
              addresses: addressData.results,
            })
          }
        })
      }
    });

  }, [])

  return (
    <div className={"w3-container"}>
      <h4>Registrierte User</h4>
      <hr/>
      <ul className="w3-ul w3-card-4">
        {props.state.users !== undefined && props.state.users.map((user, index) =>
          <UserListItem dispatch={props.dispatch} user={user} key={index} id={index} registeredUser={true}/>
        )}
      </ul>

      <h4 className={"w3-margin-top"}>Unregistrierte User</h4>
      <hr/>
      <ul className={"w3-ul w3-card-4"}>
        {props.state.unregisteredUsers !== undefined && props.state.unregisteredUsers.map((user, index) =>
          <UserListItem dispatch={props.dispatch} user={user} id={index} key={index}/>
        )}
      </ul>
    </div>
  );
}

UserList.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default UserList;