import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Actions } from "../../reducers/CalendarReducer";

function SideBar({state, dispatch, fetchEventsForUser, removeEventsForUser, setUserUpdated}) {
    return (
        <div className={"w3-container"}>
            Hier stehen die verschiedenen Kalender zur auswahl und können an- und abgewählt werden
            {state.availableCalendars.map((user, index) => <SideBarElement key={index} user={user} dispatch={dispatch} fetchEventsForUser={fetchEventsForUser} removeEventsForUser={removeEventsForUser} setUserUpdated={setUserUpdated}/>)}
        </div>
    )
}

SideBar.propTypes = {
    state : PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    fetchEventsForUser: PropTypes.func.isRequired,
    removeEventsForUser: PropTypes.func.isRequired,
    setUserUpdated: PropTypes.func.isRequired,
}

function SideBarElement({dispatch, fetchEventsForUser, removeEventsForUser, user, setUserUpdated}) {

    function handleClick() {
        if(user.active){
            removeEventsForUser(user.url);
        }else{
            fetchEventsForUser(user);
            setUserUpdated(true);
        }  
        dispatch({
            type: Actions.TOGGLE_USER_ENABLED,
            url : user.url,
        });
        
    }

    useEffect(() =>{
    },[]);

    return (
        <div className="w3-margin-bottom" style={{display: "flex", alignItems: "center"}}>
                <div className={user.active ? "w3-circle w3-"+user.color : "w3-circle w3-border w3-border-"+user.color} style={{width: "20px", height:"20px", cursor: "pointer"}} onClick={handleClick}></div>
                <span style={{marginLeft: "10px"}}>{user.name}</span>
        </div>
    )
    
}

SideBarElement.propTypes = {
    dispatch: PropTypes.func.isRequired,
    fetchEventsForUser: PropTypes.func.isRequired,
    removeEventsForUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    setUserUpdated: PropTypes.func.isRequired,
}

export default SideBar;