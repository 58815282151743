import React, {useState} from "react";
import CustomInput from "../CustomInput";
import {useFormik} from "formik";
import CustomButton from "../CustomButton";
import useFetch from "../../hooks/UseFetch";
import CitySearchResultList from "./CitySearchResultList";
import PropTypes from "prop-types";
import FetchLoading from "../FetchLoading";
import {Actions} from "../../reducers/GlobalReducer";
import {TOAST_TYPE} from "../../constants";

const validate = values => {
  const errors = {}
  if (!values.city) {
    errors.city = "Required";
  }
  if (values.city.length > 255) {
    errors.city = "max. 255 Zeichen"
  }
  // this indicates coordinates
  if (values.city.indexOf(',') !== -1) {
    let coords = values.city.split(',');
    if (Number.isNaN(Number(coords[0])) || Number.isNaN(Number((coords[1])))) {
      errors.city = "Latitude and Longitude has to be entered as XX.YYY,XX.YYY";
    }
    if (coords[0] < -90 || coords[0] > 90) {
      errors.city = "latitude has to be between -90 and 90"
    }
    if (coords[1] < -180 || coords[1] > 180) {
      errors.city = "longitude hast to be between -180 and 180"
    }
  } else {
    if (!Number.isNaN(Number(values.city)) && !values.country) {
      errors.country = "Required";
    }
    if (values.city && !Number.isNaN(Number(values.city)) && values.country.length !== 2) {
      errors.country = "Der Ländercode muss genau 2 Buchstaben haben"
    }
  }

  return errors;
}

function AddCity(props) {
  const fetch = useFetch();
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState({});
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [loading, setLoading] = useState(false);
  const formAddCity = useFormik({
      initialValues: {
        city: "",
        country: "",
      },
      validate,
      onSubmit: values => handleCitySearchSubmit(values)
    }
  );

  function handleCitySearchSubmit(values) {
    let url = "";
    values.city = values.city.trim();
    setSearchTriggered(true)
    if (values.city.indexOf(',') !== -1) {
      let coords = values.city.split(',');
      url = "/geolocation/city/?lat=" + coords[0] + "&lon=" + coords[1];
    } else if (!Number.isNaN(Number(values.city))) {
      url = "/geolocation/coordinates/?zip=" + values.city + "&country=" + values.country;
    } else {
      url = "/geolocation/coordinates/?name=" + values.city;
      if (values.country) {
        url = url + "&country=" + values.country;
      }
    }
    fetch._get(url, (data, status) => {
      setCities([]);
      setCity({})
      if (status === 200) {
        //ToDo: filter duplicated cities by identical lat and lon
        if (Array.isArray(data)) {
          setCities(data);
        } else {
          setCity(data);
        }
      }
    })
  }

  function getCurrentPosition(e) {
    e.preventDefault();
    setLoading(true);
    navigator.geolocation.getCurrentPosition(location => {
      formAddCity.setFieldValue("city", location.coords.latitude + "," + location.coords.longitude, false);
      formAddCity.submitForm();
      setLoading(false);
    }, () => {
      props.globalDispatch({
        type: Actions.DISPLAY_TOAST,
        toast:{
          type: TOAST_TYPE.ERROR,
          message: "Standort konnte nicht gefunden werden"
        }
      });
    });

  }

  return (
    <React.Fragment>
      <form className="w3-row w3-row-padding" onSubmit={formAddCity.handleSubmit}>
        <div className="w3-col m6 "><CustomInput type={"text"} id={"city"} onChange={formAddCity.handleChange}
                                                   value={formAddCity.values.city}
                                                   touched={formAddCity.touched.city} error={formAddCity.errors.city}
                                                   text={"Stadt/PLZ/Längen-&Breitengrad*"}/></div>
        <div className="w3-col m3 "><CustomInput type={"text"} id={"country"} onChange={formAddCity.handleChange}
                                                   value={formAddCity.values.country}
                                                   touched={formAddCity.touched.country}
                                                   error={formAddCity.errors.country} text={"Ländercode, z.B. DE"}/>
        </div>
        <div className="w3-col m2 "><CustomButton text={"Suchen"} disabled={fetch.loading}/></div>
        <div className={"w3-col m1 "}>
          <p>
            <button className={"w3-btn w3-blue w3-block"} style={{height: "40px"}} onClick={getCurrentPosition}><i className={"fa fa-xl fa-location-dot"} />
            </button>
          </p>
        </div>
      </form>
      {(fetch.loading || loading) && <FetchLoading/>}
      {searchTriggered && <CitySearchResultList cities={cities} city={city} toggleMenu={props.toggleMenu}
                                                globalDispatch={props.globalDispatch} dispatch={props.dispatch}/>}
    </React.Fragment>
  )
}

AddCity.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  globalDispatch: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default AddCity;