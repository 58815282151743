import React, {useEffect, useReducer, useState} from "react";
import "../styles/App.css";
import NavBar from "./NavBar";
import {BrowserRouter, matchPath, Navigate, Route, Routes} from "react-router-dom";
import Login from "./Login";
import useStateWithLocalStorage from "../hooks/UseStateWithLocalStorage";
import {UserContext} from "../contexts/UserContext";
import {GlobalReducerContext} from "../contexts/GlobalReducerContext";
import BackToTop from "./BackToTop";
import {Actions, init, reducer} from "../reducers/GlobalReducer";
import useFetch from "../hooks/UseFetch";
import FetchLoading from "./FetchLoading";
import LogRocket from "logrocket";
import Registration from "./Registration";
import DataPrivacy from "./DataPrivacy";
import Footer from "./Footer";
import CookieBanner from "./CookieBanner";
import {useCookies} from "react-cookie";
import setupLogRocketReact from "logrocket-react";
import Home from "./home/Home";
import ShoppingList from "./shoppingList/ShoppingList";
import WishList from "./wishList/WishList";
import WebcamList from "./webcams/WebcamList";
import Weather from "./weather/Weather";
import RequestBug from "./RequestBug";
import Admin from "./admin/Admin";
import NotFound from "./NotFound";
import Accounts from "./accounts/Accounts";
import Toast from "./Toast";
import Calendar from "./calendar/Calendar";
import { CALENDAR_COLORS } from "../constants";

function App(props) {
  const [login, setLogin] = useStateWithLocalStorage("login", "false");
  const [cookieConsent, setCookieConsent] = useStateWithLocalStorage("cookieConsent", null);
  const [admin, setAdmin] = useState(false);
  const [state, dispatch] = useReducer(reducer, props, init);
  const [currentUser, setcurrentUser] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['consent']);
  const fetch = useFetch();


  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (cookieConsent === "true" && process.env.NODE_ENV !== 'development') {
      try {
        LogRocket.init('20zezz/familyplatform', {
          network: {
            requestSanitizer: request => {
              if (request.url.toLowerCase().indexOf('login') !== -1 || request.url.toLowerCase().indexOf('/token/refresh/') !== -1 || request.url.toLowerCase().indexOf("/registration/") !== -1) {
                request.body = {};
              }
              return request;
            }
          }
        });
        setupLogRocketReact(LogRocket);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log("Log Rocket already initialized")
      }
      if (currentUser) {
        LogRocket.identify(currentUser.email);
      }
    } else if (cookieConsent === "false") {
      // eslint-disable-next-line
      Object.keys(cookies).map(cookieName => {
        if (cookieName.startsWith("_lr_")) {
          removeCookie(cookieName);
        }
      });
      if (LogRocket.sessionURL !== "Must call LogRocket.init() before sessionURL becomes available." && LogRocket.sessionURL !== null && LogRocket.sessionURL !== undefined)
        window.location.reload();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookieConsent, currentUser]);


  function handleScroll() {
    if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
      dispatch({
        type: Actions.TOGGLE_BACK_TO_TOP,
        toTopVisible: true
      });
    } else {
      dispatch({
        type: Actions.TOGGLE_BACK_TO_TOP,
        toTopVisible: false
      });
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  }, []);

  useEffect(() => {
    if (login === "true" || login === true) {
      fetch._get("/users/current/", data => {
        setcurrentUser({'url': data.url, 'first_name': data.first_name, 'email': data.email});
      });
      fetch._get("/users/", data => {
        const userList = []
        data.map((user, index) =>{
          user.color = CALENDAR_COLORS[index];
          userList.push(user)
        })
        dispatch({
          type: Actions.USER_FETCH_RETURNED,
          users: userList,
        });
      });
      fetch._get("/users/current/admin/", data => {
        setAdmin(!!data);
      });
    } else {
      setcurrentUser({});
      dispatch({
        type: Actions.USER_FETCH_RETURNED,
        users: {},
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login]);


  return (
    <GlobalReducerContext.Provider value={{state, dispatch}}>
      <UserContext.Provider value={{login, setLogin, currentUser}}>
        <BrowserRouter>
          <NavBar admin={admin}/>
          <div className="w3-container" style={{maxWidth: "2000px", margin: "0 auto"}}>
            <div className="w3-display-topmiddle" style={{
              position: "fixed",
              zIndex: "100",
              display: "flex",
              alignItems: "center",
              flexDirection: "column"
            }}>
              {state.toast.map((toast, index) => {
                  return <Toast key={index} toast={toast} dispatch={dispatch}/>
                }
              )}
            </div>
            {state.loadingSpinner && <FetchLoading/>}
            {(!login || login === "false") ?
              matchPath('/app/password/:token', window.location.pathname) ?
                /*ToDo: fix issue that relaod is needed to load a different router route*/
                <Routes>
                  <Route path="/app/password/:token" element={<Registration globalDispatch={dispatch}/>}/>
                </Routes> :
                matchPath('/app/dataPrivacy', window.location.pathname) ?
                  <Routes>
                    <Route path="/app/dataPrivacy" element={<DataPrivacy/>}/>
                  </Routes> :
                  <Login dispatch={dispatch}/> :
              <Routes>
                <Route path="/">
                  <Route index element={<Navigate to={"app"} replace={true}/>}/>
                  <Route path={"app"}>
                    <Route index element={<Home globalDispatch={dispatch}/>}/>
                    <Route path={"lists"} element={<Navigate to={"shoppingList"} replace={true}/>}/>
                    <Route path="lists/shoppingList" element={<ShoppingList/>}/>
                    <Route path="lists/wishList" element={<WishList/>}/>
                    <Route path="calendar" element={<Calendar />} />
                    <Route path="webcams" element={<WebcamList/>}/>
                    <Route path="weather" element={<Weather globalDispatch={dispatch}/>}/>
                    <Route path={"settings"} element={<Navigate to={"ticket"} replace={true}/>}/>
                    <Route path="settings/ticket" element={<RequestBug/>}/>
                    <Route path="settings/accounts" element={<Accounts globalDispatch={dispatch}/>}/>
                    <Route path="settings/admin" element={<Admin/>}/>
                    <Route path="settings/logout" element={<Navigate to="/" replace={true}/>}/>
                    <Route path="dataPrivacy" element={<DataPrivacy/>}/>
                  </Route>
                  <Route path="*" element={<NotFound/>}/>
                </Route>
              </Routes>}
          </div>
          <Footer setCookieConsent={setCookieConsent}/>
        </BrowserRouter>
        {state.toTopVisible && <BackToTop/>}
        {(cookieConsent === "null" || cookieConsent === null) && <CookieBanner setCookieConsent={setCookieConsent}/>}
      </UserContext.Provider>
    </GlobalReducerContext.Provider>
  );
}

export default App;
