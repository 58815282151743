import React, {useEffect, useState} from "react";
import NavBarItem from "./NavBarItem";
import PropTypes from 'prop-types';

import "../styles/Navbar.css";

function NavBar(props) {

  const [menuPoints, setMenuPoints] = useState([]);
  useEffect(() => {
      // eslint-disable-next-line no-extra-boolean-cast
    if (!!props.admin) {
      setMenuPoints ([
        {name: "Home", id: 1, linkTo: "/", image: "home"},
        {
          name: "Listen",
          id: 2,
          image: "listen",
          linkTo: "/lists",
          subMenu: [
            {name: "Einkaufsliste", id: 21, linkTo: "/lists/shoppingList"},
            {name: "Wunschliste", id: 22, linkTo: "/lists/wishList"},
          ],
        },
        {name: "Kalender", id: 3, linkTo: "/calendar", image: "kalender"},
        {name: "Wetter", id: 4, linkTo: "/weather", image: "wetter"},
        {name: "Webcams", id: 5, linkTo: "/webcams", image: "webcams"},
        {
          name: "Settings",
          id: 6,
          image: "settings",
          linkTo: "/settings",
          subMenu: [
            {name: "Request/Bug", id: 61, linkTo: "/settings/ticket"},
            {name: "Profil/Kalender", id: 62, linkTo: "/settings/accounts"},
            {name: "Admin", id: 63, linkTo: "/settings/admin"},
            {name: "Logout", id: 64, linkTo: "/settings/logout"},
          ],
        },
      ]);
    } else {
      setMenuPoints([
        {name: "Home", id: 1, linkTo: "/", image: "home"},
        {
          name: "Listen",
          id: 2,
          image: "listen",
          linkTo: "/lists",
          subMenu: [
            {name: "Einkaufsliste", id: 21, linkTo: "/lists/shoppingList"},
            {name: "Wunschliste", id: 22, linkTo: "/lists/wishList"},
          ],
        },
        {name: "Kalender", id: 3, linkTo: "/calendar", image: "kalender"},
        {name: "Wetter", id: 4, linkTo: "/weather", image: "wetter"},
        {name: "Webcams", id: 5, linkTo: "/webcams", image: "webcams"},
        {
          name: "Settings",
          id: 6,
          image: "settings",
          linkTo: "/settings",
          subMenu: [
            {name: "Request/Bug", id: 61, linkTo: "/settings/ticket"},
            {name: "Profil/Kalender", id: 62, linkTo: "/settings/accounts"},
            {name: "Logout", id: 64, linkTo: "/settings/logout"},
          ],
        },
      ]);
    }},
    [props.admin]
  );


return (
  <React.Fragment>
    <input type="checkbox" id="responsive-nav"/>
    <label htmlFor="responsive-nav" className="responsive-nav-label">
      <span>&#9776;</span>Menü
    </label>
    <nav className="w3-container">
      <ul>
        {menuPoints.map((menuPoint) => (
          <NavBarItem key={menuPoint.id} menuPoint={menuPoint}/>
        ))}
      </ul>
    </nav>
  </React.Fragment>
);
}

NavBar.propTypes = {
  admin: PropTypes.bool.isRequired,
};

export default NavBar;
