import React, {useReducer} from "react";
import {init, reducer} from "../../reducers/WishlistReducer";
import AddWishListEntry from "./AddWishListEntry";
import ProductTable from "../ProductTable";
import {LIST_TYPE} from "../../constants";

function WishList() {
  const [state, dispatch] = useReducer(reducer, null, init);

  return (
    <React.Fragment>
      <div className="w3-card">
        <AddWishListEntry dispatch={dispatch}/>
      </div>
      <div className="w3-card">
        <div className="w3-container w3-margin-top w3-grey">
          <h2>Wunschliste</h2>
        </div>
        <ProductTable
          state={state}
          dispatch={dispatch}
          listType={LIST_TYPE.WISHLIST}
        />
      </div>
    </React.Fragment>
  );
}

export default WishList;