import {useContext, useState} from "react";
import {UserContext} from "../contexts/UserContext";
import Cookies from "js-cookie";
import {API_VERSION} from "../constants";

let isRefreshing = false;
let refreshPromise = null;

async function tryRefreshToken() {
  const response = await fetch(`${API_VERSION}/token/refresh/`, {
    method: 'post', 
    credentials: 'include', 
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  
  if(!response.ok){
      throw Error(`${response.status}: ${response.statusText}`);
  }
}

function useFetch() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const {setLogin} = useContext(UserContext);

  const handleRespose = async (response, callback, options) =>{
    if(response.status === 401){
        await refreshTokenAndRetry(response, callback, options);
    }else if(!response.ok){
      setError(`${response.status}: ${response.statusText}`);
    } else {
      setError("");
    }    
    setLoading(false);
    return handleResponseType(response);
  };

  const refreshTokenAndRetry = async (response, callback, options) =>{
    if(!isRefreshing){
      isRefreshing = true;
      try{
        await tryRefreshToken();
        await fetchData(response.url, options, callback);
      }catch(error){
        setLogin("false");
      } finally{
        isRefreshing = false;
      } 
    }else{
      if(!refreshPromise){
        refreshPromise = new Promise((resolve) =>{
          const interval = setInterval(() =>{
            if (!isRefreshing){
              clearInterval(interval);
              refreshPromise = null;
              resolve();
            }
          }, 100);
        });
      }
      await refreshPromise;
      await fetchData(response.url, options, callback);
    }
  }

  const fetchData = async (request, options, callback) =>{
    setLoading(true);
    try {
      const url = !request.startsWith("http") && !request.startsWith(API_VERSION) ? `${API_VERSION}${request}` : request;
      const response = await fetch(url, {
        credentials: 'include',
        ...options,
      });
      const status = response.status;

      const data = await handleRespose(response, callback, options);
      callback(data, status);
    } catch (error){
      setError(error.messagte);
      setLoading(false);
    }
  };

  const createFetchRequest = (method, headers, body) => {
    return {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken'),
        ...headers,
      },
      body: JSON.stringify(body),
    };
  };

  const _get = (request, callback) => {
    fetchData(request, createFetchRequest('get', {}), callback);
  };

  const _postFile = (request, requestData, callback) => {
    fetchData(request, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken'),
      },
      body: requestData
    }, callback);
  };

  const _post = (request, parameter, callback) => {
    fetchData(request, createFetchRequest('post', {}, parameter), callback);
  };

  const _delete = (request, callback) => {
    fetchData(request, createFetchRequest('delete', {}), callback);    
  };

  const _update = (request, parameter, callback) => {
    fetchData(request, createFetchRequest('put', {}, parameter), callback);
  };

  const _patch = (request, parameter, callback) => {
    fetchData(request, createFetchRequest('PATCH', {}, parameter), callback);
  };

  async function handleResponseType(response) {
    setLoading(false);
    let contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return response.json();
    } else {
      return response.text();
    }
  }

  return {_get, _post, _delete, _update, _patch, _postFile, loading, error, setError};
}

export default useFetch;
