import React, {useEffect, useState} from "react";
import useFetch from "../hooks/UseFetch";
import PropTypes from "prop-types";

function Pagination(props) {
  const [next, setNext] = useState("");
  const [previous, setPrevious] = useState("");
  const [current, setCurrent] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const fetch = useFetch();
  const MAX_DISPLAYED_PAGES = 10;
  const pageSize = props.pageSize || 50;

  useEffect(() => {
    setNext(props.initialNext);
  }, [props.initialNext]);

  useEffect(() => {
    setPageNumber(Math.ceil(props.count / pageSize));
  }, [props.count]);

  function handlePaginationClick(link, nextActive) {
    if (link) {
      fetch._get(link, (data, status) => {
        props.handleFetchDataCallback(data, status);
        if(status < 300){
          setCurrent(nextActive);
          setNext(data.next);
          setPrevious(data.previous);
        }
      })
    }
  }

  if (pageNumber <= 1) {
    return "";
  }

  const pagination = []
  let leftSide = 1;
  let rightSide = MAX_DISPLAYED_PAGES;
  if (pageNumber < MAX_DISPLAYED_PAGES) {
    rightSide = pageNumber;
  } else {
    if (current > MAX_DISPLAYED_PAGES / 2) {
      leftSide = current + 1 - MAX_DISPLAYED_PAGES / 2;
      rightSide = leftSide + MAX_DISPLAYED_PAGES;
    }
    if (rightSide > pageNumber) {
      rightSide = pageNumber;
      leftSide = rightSide - MAX_DISPLAYED_PAGES + 1;
    }
  }
  for (let i = leftSide; i < rightSide + 1; i++) {
    if (i > pageNumber || i < 1) {
      break;
    }
    pagination.push(<PaginationItem link={props.link} page={(i)} key={i} handleClick={handlePaginationClick}
                                    pageSize={pageSize} current={current}/>)
  }

  return (
    <div className={"w3-center"} style={{bottom: "0px", position: "fixed", width: "100%"}}>
      <div className="w3-bar w3-border w3-round w3-white">
        {previous &&
        <span ref={() => '#'} className="w3-button"
              onClick={() => handlePaginationClick(previous, current - 1)}>&laquo;</span>}
        {
          pagination
        }
        {next &&
        <span ref={() => '#'} className="w3-button" onClick={() => handlePaginationClick(next, current + 1)}>&raquo;</span>}
      </div>
    </div>
  )
}

Pagination.propTypes = {
  pageSize: PropTypes.number,
  handleFetchDataCallback: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  initialNext: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

function PaginationItem(props) {
  const link = props.link + "?page=" + props.page + (props.pageSize ? "&page_size=" + props.pageSize : "");
  return (
    <span key={props.page} ref={() => '#'} className={"w3-button" + (props.page === props.current ? " w3-gray" : "")}
          onClick={() => props.handleClick(link, props.page)}>{props.page}</span>);
}

PaginationItem.propTypes = {
  link: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
}

export default Pagination;