import React from "react";
import PropTypes from "prop-types";
import useFetch from "../../hooks/UseFetch";
import {Actions as GlobalActions} from "../../reducers/GlobalReducer";
import {Actions} from "../../reducers/WeatherCityReducer";
import {TOAST_TYPE} from "../../constants";

function CitySearchResultList(props) {
  return (
    <ul className="w3-ul w3-hoverable">
      {Object.keys(props.city).length === 0 && props.cities.length === 0 ? <li>Keine Stadt gefunden</li> :
        props.cities.length > 0 ?
          props.cities.map((city, index) => {
            let cityName = city['name'];
            if (city['local_names'] !== undefined && city['local_names']['de'] !== undefined) {
              cityName = city['local_names']['de'];
            }
            return (
              <CitySearchResultListEntry name={cityName} country={city['country']} latitude={city['lat']}
                                         longitude={city['lon']} key={index} toggleMenu={props.toggleMenu}
                                         globalDispatch={props.globalDispatch} dispatch={props.dispatch}/>)
          }) :
          <CitySearchResultListEntry name={props.city['name']} country={props.city['country']}
                                     latitude={props.city['lat']} longitude={props.city['lon']}
                                     toggleMenu={props.toggleMenu} globalDispatch={props.globalDispatch}
                                     dispatch={props.dispatch}/>}
    </ul>
  )
    ;
}

CitySearchResultList.propTypes = {
  cities: PropTypes.array,
  city: PropTypes.object,
  toggleMenu: PropTypes.func.isRequired,
  globalDispatch: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
}

function CitySearchResultListEntry(props) {
  const fetch = useFetch();

  function addCity() {
    fetch._post("/weather/", {
      "name": props.name,
      "country": props.country,
      "latitude": props.latitude,
      "longitude": props.longitude
    }, (data, status) => {
      if (status === 201) {
        props.dispatch({
          type: Actions.CITIES_ADD,
          city: data
        });
        props.globalDispatch({
          type: GlobalActions.DISPLAY_TOAST,
          toast:{
            type: TOAST_TYPE.SUCCESS,
            message: props.name + " hinzugefügt"
          }
        });
        props.toggleMenu(prevState => !prevState);

      }
    });

  }

  return (
    <li style={{cursor: "pointer"}} onClick={() => addCity()}>{props.name}<br/>{props.country}</li>
  );
}

CitySearchResultListEntry.propTypes = {
  name: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  globalDispatch: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default CitySearchResultList;