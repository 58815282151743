import React, {useContext, useEffect, useRef, useState} from "react";
import CustomIconButton from "./CustomIconButton";
import useFetch from "../hooks/UseFetch";
import {Actions} from "../reducers/ShoppinglistReducers";
import {Actions as WishlistActions} from "../reducers/WishlistReducer";
import PropTypes from "prop-types";
import {useFormik} from "formik";
import {ShoppinglistEntryDisplay, ShoppinglistEntryEdit, WishlistEntryDisplay, WishlistEntryEdit} from "./ProductEntry";
import {LIST_TYPE} from "../constants";
import {GlobalReducerContext} from "../contexts/GlobalReducerContext";

const validateShoppinglist = values => {
  let errors = {};

  if (!values.amount) {
    errors.amount = "Required";
  } else if (values.amount === 0) {
    errors.amount = "Menge muss größer 0 sein";
  } else if (values.amount < 0) {
    errors.amount = "Keine negativen Mengen möglich";
  }

  if (!values.name) {
    errors.name = "Required";
  } else if (values.name.length > 255) {
    errors.name = "max. 255 Zeichen erlaubt";
  }

  if (!values.user) {
    errors.user = "Required"
  }

  return errors;
};

const validateWishlist = values => {
  let errors = {};

  if (!values.name) {
    errors.name = "Required";
  } else if (values.name.length > 255) {
    errors.name = "max. 255 Zeichen erlaubt";
  }

  if (!values.user) {
    errors.user = "Required"
  }

  return errors;
};

function ProductRow(props) {
  const [editMode, setEditMode] = useState(false);
  const [user, setUser] = useState({});
  const [bought, setBought] = useState(Boolean(props.entry.is_bought));
  const {state} = useContext(GlobalReducerContext);
  const fetch = useFetch();
  const color = useRef("white");

  const form = useFormik({
    initialValues: {
      name: props.entry.name,
      amount: props.entry.amount,
      user: props.entry.user,
      store: props.entry.store,
      link: props.entry.hyperlink,
    },
    validate: LIST_TYPE.SHOPPINGLIST === props.listType ? validateShoppinglist : validateWishlist,
    onSubmit: values => handleUpdateArticle(values)
  });

  /**
   * find the user for the entry inside the user list to avoid additional requests
   */
  useEffect(() => {
    let entryUser = state.users.find(user => user.url === props.entry.user);
    setUser(entryUser !== undefined ? entryUser : {})
  }, [state.users]);


  //Todo: escape key ends editing

  function handleDeleteClick() {
    if (LIST_TYPE.SHOPPINGLIST === props.listType) {
      fetch._patch(props.entry.url + "delete/", ({'is_deleted': true}), () => {
        props.dispatch({
          type: Actions.SHOPPINGLIST_DELETE,
          url: props.entry.url
        });
      })
    }
    if (LIST_TYPE.WISHLIST === props.listType) {
      fetch._delete(props.entry.url, () => {
        props.dispatch({
          type: WishlistActions.WISHLIST_DELETE,
          url: props.entry.url
        })
      })
    }
  }


  /**
   * update article
   * @param values of the article which will be updated
   */
  function handleUpdateArticle(values) {
    if (LIST_TYPE.SHOPPINGLIST === props.listType) {
      fetch._update(props.entry.url, {
        'amount': values.amount,
        'user': values.user,
        'name': values.name,
        'is_bought': false,
        'store': values.store,
        'category': props.entry.category,
      }, (data) => {
        props.dispatch({
          type: Actions.SHOPPINGLIST_UPDATE,
          entry: data,
          url: props.entry.url,
        });
        setBought(false);
        setEditMode(editMode => !editMode)
      })
    }
    if (LIST_TYPE.WISHLIST === props.listType) {
      fetch._update(props.entry.url, {
        'user': values.user,
        'name': values.name,
        'link': values.link,
      }, (data) => {
        props.dispatch({
          type: WishlistActions.WISHLIST_UPDATE,
          entry: data,
          url: props.entry.url,
        });
        setEditMode(editMode => !editMode)
      })
    }
  }

  useEffect(() =>{
    if (props.listType !== LIST_TYPE.SHOPPINGLIST){
      return;
    }
    let category = props.state.categories.find(category => category.url === props.entry.category);
    if (category === undefined){
      return;
    }
    color.current = category.color;
  },[props.state.categories])

  return (
    <li className="w3-bar w3-display-container" style={{borderLeft:"20px solid "+color.current}}>
      {props.listType === LIST_TYPE.SHOPPINGLIST ?
        !editMode ?
          <ShoppinglistEntryDisplay entry={props.entry} dispatch={props.dispatch}
                                    bought={bought} setBought={setBought} userName={user.first_name || ""}/> :
          <ShoppinglistEntryEdit form={form} entry={props.entry}/> :
        !editMode ?
          <WishlistEntryDisplay entry={props.entry} userName={user.first_name || ""}/> :
          <WishlistEntryEdit entry={props.entry} form={form}/>
      }
      <div className="w3-row-padding w3-display-right w3-margin-right">
        {!editMode ?
          <React.Fragment>
            <CustomIconButton altText="edit" icon="edit.png" class="w3-bar-item padding-0 w3-margin-right" round={true}
                              onClick={() => setEditMode(true)}/>
            <CustomIconButton altText="delete" icon="delete.png" class="w3-bar-item w3-red padding-0" round={true}
                              onClick={handleDeleteClick}/>
          </React.Fragment> :
          <React.Fragment>
            <CustomIconButton altText="save" icon="confirm.png" class="w3-bar-item padding-0 w3-margin-right"
                              round={true}
                              onClick={form.handleSubmit}/>
            <CustomIconButton altText="cancel" icon="cancel.png" class="w3-bar-item w3-red padding-0" round={true}
                              onClick={() => {
                                setEditMode(false);
                                form.resetForm();
                              }}/>
          </React.Fragment>}
      </div>
    </li>
  );
}

ProductRow.propTypes = {
  entry: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  listType: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
};

export default ProductRow;
