import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import CustomInputError from "./CustomInputError";

function CustomInput(props) {
  const [labelVisibility, setLabelVisibility] = useState("none");

  useEffect(() => {
    if (props.value === "" && labelVisibility === "block") {
      setLabelVisibility("none");
      if (typeof props.decreaseContainerHeight === "function") {
        props.decreaseContainerHeight()
      }
    } else if (props.value !== "" && labelVisibility === "none") {
      if (typeof props.increaseContainerHeight === "function") {
        props.increaseContainerHeight()
      }
      setLabelVisibility("block");
    }
  }, [props.value]);

  return (
    <p>
      <input
        className="w3-input w3-border-blue"
        name={props.name}
        type={props.type}
        placeholder={props.text}
        required={props.required}
        id={props.id}
        autoComplete={props.autocomplete}
        value={props.value}
        onChange={props.onChange}
        ref = {props.inputRef}
      />
      <label style={{display: labelVisibility}} htmlFor={props.id}
             className="w3-text-gray margin-left-6">{props.text}</label>
      {props.touched && props.error ? <CustomInputError message={props.error}/> : null}
    </p>
  );

}

CustomInput.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string,
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  autocomplete: PropTypes.string,
  decreaseContainerHeight: PropTypes.func,
  increaseContainerHeight: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  inputRef: PropTypes.func,
};

export default CustomInput;
