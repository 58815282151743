import CustomInputError from "./CustomInputError";
import React, {forwardRef} from "react";
import PropTypes from "prop-types"

// eslint-disable-next-line react/display-name
const CustomFileInput = forwardRef((props, ref) => (
      <p>
        <input
          className="w3-input w3-border-blue"
          type={props.type}
          placeholder={props.text}
          name={props.name}
          autoComplete="off"
          accept="image/*"
          data-max-size="500000"
          id={props.id}
          multiple
          ref={ref}
          onChange={props.onChange}
          style={props.hidden ? {"display" : "none"}: null}
        />
        <label htmlFor={props.id} style={{display: "block"}} className="w3-text-gray margin-left-6">{props.text}
          {props.icon && <i className={props.icon} style={props.iconStyle}>&nbsp;</i>}
        </label>
        {props.touched && props.error ?
          <CustomInputError message={props.error}/> : null}
      </p>
    )
  )
;

export default CustomFileInput;

CustomFileInput.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string,
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  autocomplete: PropTypes.string,
  decreaseContainerHeight: PropTypes.func,
  increaseContainerHeight: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.array,
  hidden: PropTypes.bool,
  icon: PropTypes.string,
  iconStyle: PropTypes.object,
};