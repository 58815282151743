export const Actions = {
  WISHLIST_FETCH_RETURNED: "WISHLIST_FETCH_RETURNED",
  WISHLIST_DELETE: "WISHLIST_DELETE",
  WISHLIST_UPDATE: "WISHLIST_UPDATE",
  WISHLIST_ADD: "WISHLIST_ADD",
};

export const init = () => {
  return {
    entries: [],
  }
};

export const reducer = (state, action) => {
  switch(action.type){
    case(Actions.WISHLIST_FETCH_RETURNED):
      return {
        ...state,
        entries : action.entries,
      };
    case Actions.WISHLIST_ADD: {
      let entries = [
        action.entry,
        ...state.entries,
      ];
      return {
        ...state,
        entries: entries
      };
    }
    case Actions.WISHLIST_DELETE: {
      let entries = [...state.entries];
      return {
        ...state,
        entries: entries.filter(entry => entry.url !== action.url)
      }
    }
    case Actions.WISHLIST_UPDATE:{
      let entries = [...state.entries];
      let entryToUpdate = entries.find(entry => entry.url === action.url);
      entryToUpdate['name'] = action.entry.name;
      entryToUpdate['user'] = action.entry.user;
      entryToUpdate['hyperlink'] = action.entry.hyperlink;

      return{
        ...state,
        entries: entries
      }
    }
    default: return state;
  }
};