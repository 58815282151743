import React, {useContext, useState} from "react";
import CustomInput from "./CustomInput";
import CustomButton from "./CustomButton";
import useFetch from "../hooks/UseFetch";
import FetchLoading from "./FetchLoading";
import {UserContext} from "../contexts/UserContext";
import {useFormik} from "formik";
import {Actions} from "../reducers/GlobalReducer";
import PropTypes from "prop-types";
import {TOAST_TYPE} from "../constants";

const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = "Required"
  }
  if (!values.password) {
    errors.password = "Required"
  }
  return errors;
};

const validateResetPassword = values => {
  const errors = {};
  if (!values.email) {
    errors.email = "Required"
  }

  return errors;
};

const Login = (props) => {
  const fetch = useFetch();
  const {setLogin} = useContext(UserContext);
  const [passwordReset, setPasswordReset] = useState(false);

  const form = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: values => handleLoginSubmit(values)
  });

  const resetPasswordForm = useFormik({
    initialValues: {
      email: "",
    },
    validate: values => validateResetPassword(values),
    onSubmit: values => handleResetPasswordSubmit(values)
  });

  function handleLoginSubmit(values) {
    fetch._post("/login/", {"email": values.email, "password": values.password}, (data, status) => {
      if (status === 200) {
        setLogin("true");
      } else {
        props.dispatch({
          type: Actions.DISPLAY_TOAST,
          toast: {
            type: TOAST_TYPE.ERROR,
            message: "Kein Account mit diesen Zugangsdaten gefunden"
          }
        });
      }
    });
  }

  function handleResetPasswordSubmit(values) {
    let mail = window.btoa(values.email);
    fetch._post("/users/forgotPassword/" + mail + "/", {}, (data, status) => {
        if (status < 500) {
          props.dispatch({
            type: Actions.DISPLAY_TOAST,
            toast: {
              type: TOAST_TYPE.SUCCESS,
              message: "Password zurückgesetzt. Bitte den Posteingang überprüfen"
            }
          });
          resetPasswordForm.resetForm()
        }
      }
    );
  }

  return (
    <React.Fragment>
      {fetch.loading ? <FetchLoading/> :
        /*(fetch.error !== "") ? <FetchError error={fetch.error}/> :*/ ""}
      {!passwordReset && <div style={{display: "flex", justifyContent: "center"}}>
        <div className="w3-half w3-card-4">
          <div className="w3-gray w3-container"><h3>Login</h3></div>
          <form
            className="w3-container"
            onSubmit={form.handleSubmit}
          >
            <CustomInput
              type="email"
              id="email"
              name="email"
              text="E-Mail*"
              value={form.values.email}
              onChange={form.handleChange}
              error={form.errors.email}
              touched={form.touched.email}
            />
            <CustomInput
              type="password"
              id="password"
              name="password"
              text="Passwort*"
              value={form.values.password}
              onChange={form.handleChange}
              error={form.errors.password}
              touched={form.touched.password}
            />
            <CustomButton text="Check-in!"/>
          </form>
          <span className="w3-right w3-margin-right" onClick={() => setPasswordReset(true)} style={{cursor: "pointer"}}>Passwort vergessen</span>
        </div>
      </div>}
      {passwordReset && <div style={{display: "flex", justifyContent: "center"}}>
        <div className="w3-half w3-card-4">
          <div className="w3-gray w3-container" onClick={() => setPasswordReset(false)}>
            <div className="arrow left" style={{borderColor: "black", cursor: "pointer"}}/>
            <h3 style={{display: "inline-block"}}>Passwort vergessen</h3>
          </div>
          <form
            className="w3-container"
            onSubmit={resetPasswordForm.handleSubmit}
          >
            <CustomInput
              type="email"
              id="email"
              name="email"
              text="E-Mail*"
              value={resetPasswordForm.values.email}
              onChange={resetPasswordForm.handleChange}
              error={resetPasswordForm.errors.email}
              touched={resetPasswordForm.touched.email}
            />
            <CustomButton text="Passwort zurücksetzen"/></form>
        </div>
      </div>}
    </React.Fragment>
  );
};

Login.propTypes = {
  dispatch: PropTypes.func.isRequired
};

export default Login;
