import React, {useContext} from "react";
import {LinkProps, NavLink, useMatch, useResolvedPath} from "react-router-dom";
import PropTypes from "prop-types";
import useFetch from "../hooks/UseFetch";
import {UserContext} from "../contexts/UserContext";

function NavBarItem(props) {

  const fetch = useFetch();
  let userContext = useContext(UserContext);

  function handleLogout() {
    fetch._post("/logout/", {}, () => {
      userContext.setLogin("false");
    })
  }

  function createLink() {
    let link =
      props.menuPoint.linkTo != null ? props.menuPoint.linkTo : "#";
    if (props.menuPoint.linkTo === "/") {
      return (
        <CustomLink to={link} end={true} name={props.menuPoint.name}/>
      );
    }
    if (props.menuPoint.linkTo === "/settings/logout") {
      if (userContext.login === "false") {
        return
      } else {
        return (
          <span onClick={handleLogout}>
            <CustomLink to={link} name={props.menuPoint.name}/>
      </span>);
      }

    }
    return (
      <CustomLink to={link} name={props.menuPoint.name}/>
    );
  }

  function CustomLink({to, name, end = false, ...props}: LinkProps) {
    /*ToDo: base_url should only be a workaround to solve the issue, that baseurl="/app" is no longer supported to render / in BroswerRouter in App.js */
    const base_url = "app";
    let resolved = useResolvedPath(base_url + to);
    let match = useMatch({path: resolved.pathname, end: end})

    return (
      <NavLink style={{background: match ? "#4096ee" : ""}} to={base_url + to} {...props}>
        {createMenuIcon()}
        {name}
      </NavLink>
    )
  }

  function createMenuIcon() {
    if (props.menuPoint.image == null) {
      return null;
    } else {
      var imagePath = "/static/img/icons/" + props.menuPoint.image + ".png";
      return <img src={imagePath} alt={props.menuPoint.name}/>;
    }
  }

  function createSubmenu() {
    if (props.menuPoint.subMenu != null) {
      return (
        <ul>
          {props.menuPoint.subMenu.map((menuPoint) => (
            <NavBarItem menuPoint={menuPoint} key={menuPoint.id}/>
          ))}
        </ul>
      );
    }
  }

  function closeMobileMenu() {
    let navBar = document.getElementById("responsive-nav");
    navBar.checked = false;
  }

  return (
    <li onClick={closeMobileMenu}>
      {createLink()}
      {createSubmenu()}
    </li>
  );
}

NavBarItem.propTypes = {
  menuPoint: PropTypes.object.isRequired,
};

export default NavBarItem;
