export const Actions = {
  USER_FULL_DETAILS_RETURNED: "USER_FULL_DETAILS_RETURNED",
  USER_UPDATED: "USER_UPDATED",
  UNREGISTERED_USERS_RETURNED: "USER_UNREGISTERED_RETURNED",
  UNREGISTERED_USER_ADDED: "UNREGISTERED_USER_ADDED",
  USER_PROFILE_ADDRESS_RETURNED: "USER_PROFILE_ADDRESS_RETURNED"
}

export const init = () => {
  return {
    users: [],
    unregisteredUsers: [],
  }
};

export const reducer = (state, action) => {
  switch (action.type) {
    case(Actions.USER_FULL_DETAILS_RETURNED):
      return {
        ...state,
        users: action.users,
      };
    case(Actions.USER_UPDATED): {
      let tempUsers = [...state.users];
      let userToUpdate = tempUsers.find(user => user.url === action.url);
      userToUpdate.is_active = action.is_active;
      return {
        ...state,
        users: tempUsers,
      };
    }
    case(Actions.UNREGISTERED_USERS_RETURNED):
      return {
        ...state,
        unregisteredUsers: action.users,
      };
    case(Actions.UNREGISTERED_USER_ADDED):
      return {
        ...state,
        unregisteredUsers: [...state.unregisteredUsers, action.user]
      };
    case(Actions.USER_PROFILE_ADDRESS_RETURNED): {
      let tempUsers = [...state.users];
      action.profiles.map(profile => {
        let user_to_update = tempUsers.find(user => user.url === profile.user);
        if(user_to_update !== undefined){
          user_to_update.address = action.addresses.find(address => address.url === profile.address);
        }
      })
      return {
        ...state,
        users: tempUsers,
      }
    }
    default:
      return {
        ...state
      }
  }
}