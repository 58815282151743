import React, {useContext} from "react";
import CustomInput from "../CustomInput";
import CustomButton from "../CustomButton";
import {useFormik} from "formik";
import {Actions as GlobalActions} from "../../reducers/GlobalReducer";
import PropTypes from "prop-types";
import useFetch from "../../hooks/UseFetch";
import {GlobalReducerContext} from "../../contexts/GlobalReducerContext";
import {Actions} from "../../reducers/AdminReducer";
import {TOAST_TYPE} from "../../constants";

const validate = (values) => {
  const errors = {};
  if (!values.firstname) {
    errors.firstname = "Required"
  }

  if (!values.email) {
    errors.email = "Required"
  }

  return errors;
};

function AddUser(props) {
  const fetch = useFetch();
  const {dispatch} = useContext(GlobalReducerContext);
  const form = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
    },
    validate,
    onSubmit: (values) => handleFormSubmit(values),
  });

  function handleFormSubmit(values) {
    fetch._post("/registration/", {
        "first_name": values.firstname,
        "last_name": values.lastname,
        "email": values.email
      }, (data, status) => {
        if (status < 300) {
          dispatch({
            type: GlobalActions.DISPLAY_TOAST,
            toast: {
              type: TOAST_TYPE.SUCCESS,
              message: "Nutzer " + values.firstname + " hinzugefügt!"
            },
          });
          form.setSubmitting(false);
          form.resetForm();
          props.dispatch({
            type: Actions.UNREGISTERED_USER_ADDED,
            user: data,
          });
        } else {
          dispatch({
            type: GlobalActions.DISPLAY_TOAST,
            toast: {
              type: TOAST_TYPE.ERROR,
              message: data
            }
          })
        }
      }
    )
  }

  return (
    <div className="w3-card-4">
      <div className="w3-container w3-light-gray"><h3>Nutzer anlegen</h3></div>
      <form className="w3-container" onSubmit={form.handleSubmit}>
        <CustomInput type="text" id="firstname" text="Vorname" onChange={form.handleChange}
                     value={form.values.firstname} error={form.errors.firstname} touched={form.touched.firstname}/>
        <CustomInput type="text" id="lastname" text="Nachname" onChange={form.handleChange}
                     value={form.values.lastname}
                     error={form.errors.lastname} touched={form.touched.lastname}/>
        <CustomInput type="email" id="email" text="E-Mail Adresse" onChange={form.handleChange}
                     value={form.values.email} error={form.errors.email} touched={form.touched.email}/>
        <CustomButton text="Registrieren" disabled={fetch.loading}
                      customClass={fetch.loading ? "w3-grey" : null}/>
      </form>
    </div>
  );
}

AddUser.propTypes = {
  setError: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default AddUser;
