import React from "react";
import PropTypes from "prop-types";

function CookieBanner(props) {

  function handleCookieDecision(consent) {
    props.setCookieConsent(consent);
  }

  return (
    <div className="w3-container w3-black" style={{position: 'fixed', left: "0", bottom: "0", width: "100%"}}>
      <h3>Diese Webseite verwendet Cookies</h3>
      <p>Wir verwenden LogRocket, ein Tool zum Monitoring des Frontends. Dafür werden Cookies benötigt, um die Zugriffe
        auf unsere Website zu analysieren und die Nutzungserfahrung zu
        optimieren.</p>
      <button className="w3-button w3-light-blue w3-margin" onClick={() => handleCookieDecision("false")}>Nur technisch
        notwendige Cookies zulassen
      </button>
      <button className="w3-button w3-light-blue w3-margin" onClick={() => handleCookieDecision("true")}>Alle Cookies
        zulassen
      </button>
    </div>
  );
}

export default CookieBanner;

CookieBanner.propTypes = {
  setCookieConsent: PropTypes.func.isRequired,
};