import React, {useContext, useEffect, useState} from "react";
import CustomInput from "../CustomInput";
import CustomSelect from "../CustomSelect";
import CustomButton from "../CustomButton";
import "../../styles/AddProduct.scss";
import useFetch from "../../hooks/UseFetch";
import {Actions} from "../../reducers/ShoppinglistReducers";
import {Actions as GlobalActions} from "../../reducers/GlobalReducer";
import {UserContext} from "../../contexts/UserContext";
import CustomInputWithFilterList from "../CustomInputWithFilterList";
import PropTypes from "prop-types"
import {useFormik} from "formik";
import {GlobalReducerContext} from "../../contexts/GlobalReducerContext";
import {TOAST_TYPE} from "../../constants";
import Modal from "../Modal";
import {HexColorInput, HexColorPicker} from "react-colorful";
import CustomInputError from "../CustomInputError";
import convert from "color-convert";
import LogRocket from "logrocket";


const userOptions = (users) => {
  if (!Array.isArray(users) && typeof users[Symbol.iterator] !== 'function') {
    // users is not iterable
    return {};
  }
  if (users === null || users.length === 0) {
    return {};
  }
  let userList = {};
  for (let user of users) {
    userList[user.url] = user.first_name;
  }
  return userList;
};

const validate = values => {
  const errors = {};
  if (!values.name || values.name.trim() === "") {
    errors.name = "Required"
  } else if (values.name.length > 255) {
    errors.name = "max. 255 Zeichen"
  }
  if (!values.amount) {
    errors.amount = "Required"
  } else if (values.amount === 0) {
    errors.amount = "0 ist keine mögliche Menge"
  } else if (values.amount < 0) {
    errors.amount = "keine negativen Mengen möglich"
  }
  if (!values.user) {
    errors.user = "User darf nicht leer sein"
    LogRocket.error("User was empty: " + values.user);
  }

  return errors;
};

const validateCategory = values => {
  const errors = {};
  if ((!values.newCategory || values.newCategory.trim() === "") && (!values.category || values.category === 0)) {
    errors.newCategory = "Kategorie kann nicht leer bleiben";
    errors.category = "Bitte Kategorie auswählen"
  }
  if (values.newCategory && values.category && values.category !== "0") {
    errors.newCategory = "Bitte nur eine Option wählen"
    errors.category = "Bitte nur eine Option wählen"
  }
  if (values.newCategory && !values.color) {
    errors.color = "Bitte eine passende Farbe auswählen"
  }

  return errors;
}

function AddEntry(props) {
  const fetch = useFetch();
  const {currentUser} = useContext(UserContext);
  const {state, dispatch} = useContext(GlobalReducerContext);
  const [articleProposals, setArticleProposals] = useState([]);
  const [labelCounter, setLabelCounter] = useState(0);
  const [category, setCategory] = useState([]);
  const [defaultValueUser, setDefaultValueUser] = useState(currentUser.url || '');
  let firstInputRef = null;

  const categoryForm = useFormik({
    initialValues: {
      category: "",
      newCategory: "",
      color: "",
    },
    validate: values => validateCategory(values),
    onSubmit: values => handleAddCategoryButtonClick(values)
  })

  useEffect(() => {
    form.setFieldValue("user", currentUser.url, true);
    setDefaultValueUser(currentUser.url || '');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.url])

  const form = useFormik({
      initialValues: {
        amount: "1",
        name: "",
        user: currentUser.url,
        store: "",
        category: "",
      },
      validate,
      onSubmit: values => handleAddProductButtonClick(values),
      enableReinitialize: true,
    }
  );

  //Todo: Set Store? Maybe indirect after submit and existing

  //Todo: load articles while typing by name
  function loadAllArticleNames() {
    fetch._get("/articlename/", (data, status) => {
      if (status === 200) {
        setArticleProposals(data);
      }
    });
  }

  /**
   * handle click on add product button and send data to backend
   */
  function handleAddProductButtonClick(values) {
    fetch._post("/shoppinglist/", {
      "name": values.name,
      "amount": values.amount,
      "user": values.user,
      "store": values.store,
      "category": values.category,
    }, (data, status) => {
      switch (status) {
        case 201:
          props.dispatch({
            type: Actions.SHOPPINGLIST_ADD,
            entry: data
          });
          dispatch({
            type: GlobalActions.DISPLAY_TOAST,
            toast: {
              type: TOAST_TYPE.SUCCESS,
              message: values.name + " hinzugefügt"
            }
          });
          form.resetForm();
          form.setSubmitting(false);
          fetch._get("/store/", (data, status) => {
            if (status < 300) {
              props.dispatch({
                type: Actions.STORES_FETCH_RETURNED,
                stores: data,
              });
            }
          });
          loadAllArticleNames();
          break;
        case 206:
          fetch._get("/category/", (data, status) => {
            switch (status) {
              case 200:
                setCategory(data);
                break;
              default:
                break;
            }
          })
          document.getElementById("categoryModal").style.display = 'block'
          break;
        case 401:
          break;
        default:
          dispatch({
            type: GlobalActions.DISPLAY_TOAST,
            toast: {
              type: TOAST_TYPE.ERROR,
              message: "Artikel konnte nicht hinzugefügt werden"
            }
          })
          LogRocket.error("Unable to add product")
          break;
      }

    });
    firstInputRef.focus();
  }

  /**
   * function to add new category and send to backend.
   * If the result is ok, the form with the article will be send again, containing the category
   * @param values of the form
   * @returns {undefined}
   */
  function handleAddCategoryButtonClick(values) {
    if (values.newCategory && values.newCategory !== "0") {
      fetch._post("/category/", {"name": values.newCategory, "color": values.color}, (data, status) => {
        switch (status) {
          case 201:
            document.getElementById("categoryModal").style.display = 'none';
            dispatch({
              type: GlobalActions.DISPLAY_TOAST,
              toast: {
                type: TOAST_TYPE.SUCCESS,
                message: "Kategorie " + data.name + " hinzugefügt"
              }
            });
            props.dispatch({
              type: Actions.CATEGORIES_ADD,
              category: data
            })
            form.setFieldValue("category", data.url, true);
            setTimeout(() => {
              form.submitForm();
            }, 0);
            categoryForm.resetForm()
            break;
          default:
            dispatch({
              type: GlobalActions.DISPLAY_TOAST,
              toast: {
                type: TOAST_TYPE.ERROR,
                message: data.name
              }
            });
        }
      });
    } else {
      document.getElementById("categoryModal").style.display = 'none';
      form.setFieldValue("category", values.category);
      form.submitForm();
      categoryForm.resetForm();
    }
  }

  /**
   * function to increase the height of the input container if a label gets displayed
   */
  function increaseContainerHeight() {
    setLabelCounter(labelCounter => labelCounter + 1);
  }

  /**
   * function to decrease the height of the input container if a label gets invisible
   */
  function decreaseContainerHeight() {
    setLabelCounter(labelCounter => labelCounter > 0 ? labelCounter - 1 : 0);
  }

  const categoryList = categories => {
    let categoryList = {};
    categoryList[0] = "";
    for (let cat of categories) {
      categoryList[cat.url] = cat.name;
    }
    return categoryList;
  }

  function handleProposalSelection(value) {
    fetch._get("/shoppinglist/getStoreByUserAndArticle/?articleName=" + value, (data, status) => {
      if (status === 200) {
        form.setFieldValue("store", data.name);
      } else {
        form.setFieldValue("store", "");
      }
    })

  }

  return (
    <React.Fragment>
      <Modal title={"Neuer Artikel entdeckt"} styleDefault={true} id={"categoryModal"} content={
        <div>
          Bitte Kategorie auswählen oder neue Kategorie hinzufügen:
          <form onSubmit={categoryForm.handleSubmit}>
            <CustomSelect id={"category"}
                          name={"category"}
                          text={"Kategorie auswählen"}
                          options={categoryList(category)}
                          defaultValue={""}
                          value={categoryForm.values.category}
                          onChange={categoryForm.handleChange}
                          error={categoryForm.errors.category}
                          touched={categoryForm.touched.category}
            />
            {(!categoryForm.values.category || categoryForm.values.category === "0") && <React.Fragment>
              oder
              <CustomInput type={"text"}
                           id={"newCategory"}
                           name={"newCategory"}
                           text={"Neue Kategory hinzufügen"}
                           onChange={categoryForm.handleChange}
                           error={categoryForm.errors.newCategory}
                           touched={categoryForm.touched.newCategory}
                           value={categoryForm.values.newCategory}
              />
              <div className={"w3-row"}>
                <div className={"w3-col s12 m6 w3-margin-bottom w3-cell"}>
                  <HexColorPicker onChange={color => categoryForm.setFieldValue("color", color, true)} id={"color"}
                                  color={categoryForm.values.color} name={"color"} style={{margin: "0 auto"}}/>
                  {categoryForm.touched.color && categoryForm.errors.color ?
                    <CustomInputError message={categoryForm.errors.color}/> : null}
                </div>
                <div className={"w3-col s12 m6 w3-cell"}>
                  <div>Hex Code: #<HexColorInput onChange={color => categoryForm.setFieldValue("color", color, true)}
                                                 color={categoryForm.values.color} maxLength={8}/></div>
                  <div>RGB Code: <input type={"text"} style={{marginLeft: "9px"}} maxLength={11}
                                        value={convert.hex.rgb(categoryForm.values.color)} onChange={e => {
                    let color = e.target.value
                    let array = color.split(',');
                    let numbers = array.map(str => Number(str));
                    categoryForm.setFieldValue("color", convert.rgb.hex(numbers), true);
                  }}/>
                  </div>
                </div>
              </div>
            </React.Fragment>
            }
            <CustomButton text="Hinzufügen" disabled={fetch.loading}
                          customClass={fetch.loading ? "w3-grey" : null}/>

          </form>
        </div>

      }
      />
      <div className="w3-container w3-grey">
        <h3>Artikel hinzufügen</h3>
      </div>
      <form className={"w3-container addArticle label_" + labelCounter} id="form" onSubmit={form.handleSubmit}>
        <span onClick={loadAllArticleNames}>
          <CustomInputWithFilterList
            text="Artikel*"
            id="article"
            name="name"
            type="text"
            autocomplete="off"
            value={form.values.name}
            setValue={value => {
              form.setFieldValue("name", value);
              handleProposalSelection(value);
            }}
            onChange={e => form.setFieldValue("name", e.target.value)}
            filterList={articleProposals}
            increaseContainerHeight={increaseContainerHeight}
            decreaseContainerHeight={decreaseContainerHeight}
            error={form.errors.name}
            touched={form.touched.name}
            inputRef={(input) => {
              firstInputRef = input
            }}
          />
        </span>
        <CustomInput
          text="Menge"
          id="amount"
          name="amount"
          defaultValue="1"
          value={form.values.amount}
          onChange={form.handleChange}
          increaseContainerHeight={increaseContainerHeight}
          decreaseContainerHeight={decreaseContainerHeight}
          error={form.errors.amount}
          touched={form.touched.amount}
          type={"text"}
        />
        <CustomInput
          text="Laden/Link"
          id="store"
          value={form.values.store}
          autocomplete="off"
          name="store"
          type="text"
          onChange={form.handleChange}
          increaseContainerHeight={increaseContainerHeight}
          decreaseContainerHeight={decreaseContainerHeight}
          error={form.errors.store}
          touched={form.touched.store}
        />
        <CustomSelect
          required={false}
          id="user"
          name="user"
          text={"Für"}
          defaultValue={defaultValueUser}
          defaultValueCanBeSelected={true}
          value={form.values.user}
          options={userOptions(state.users)}
          onChange={form.handleChange}
          increaseContainerHeight={increaseContainerHeight}
          decreaseContainerHeight={decreaseContainerHeight}
          error={form.errors.user}
          touched={form.touched.user}
        />
        <CustomButton text="Hinzufügen" disabled={fetch.loading}
                      customClass={fetch.loading ? "w3-grey" : null}/>
      </form>
    </React.Fragment>
  );
}

AddEntry.propTypes = {
  dispatch: PropTypes.func,
  state: PropTypes.object,
};

export default AddEntry;
