import React from "react";
import PropTypes from 'prop-types';

function CustomButton(props) {

  return (
    <p>
      <button
        className={"w3-btn w3-blue w3-block " + props.customClass}
        onClick={props.onClick}
        type="submit"
        disabled={props.disabled}
      >
        {props.text}
      </button>
    </p>
  );
}

CustomButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CustomButton;
