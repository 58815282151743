import React, {useEffect} from "react";
import ProductRow from "./ProductRow";
import FetchLoading from "./FetchLoading";
import FetchError from "./FetchError";
import useFetch from "../hooks/UseFetch";
import PropTypes from "prop-types";
import {Actions as ShoppinglistActions} from "../reducers/ShoppinglistReducers";
import {Actions as WishlistActions} from "../reducers/WishlistReducer";
import {LIST_TYPE} from "../constants";

function ProductTable(props) {
  const fetch = useFetch();

  /**
   * fetch articles for lists depending on list type
   */
  useEffect(() => {
      if (LIST_TYPE.SHOPPINGLIST === props.listType) {
        let query = "/shoppinglist/";
        if (props.filter) {
          let stores = props.state.stores.filter(store => store.selected === true);
          let storeNames = [];
          for (let x in stores) {
            storeNames.push(stores[x].name);
          }
          query += "?store=" + storeNames;
        }
        fetch._get(query, (data, status) => {
          if (status < 300) {
            props.dispatch({
              type: ShoppinglistActions.SHOPPINGLIST_FETCH_RETURNED,
              entries: data
            })
          }
        })
      }
      if (LIST_TYPE.WISHLIST === props.listType) {
        fetch._get("/wishlist/", (data, status) => {
          if (status < 300) {
            props.dispatch({
              type: WishlistActions.WISHLIST_FETCH_RETURNED,
              entries: data
            })
          }
        })
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filter, props.state.stores]
  )
  ;

  return (
    fetch.loading ? <FetchLoading/> :
      fetch.error !== "" ? <FetchError error={fetch.error}/> :
        <ul className="w3-ul w3-card-4">
          {props.state.entries.length === 0 ? <li className="w3-row">Keine Einträge vorhanden</li> :
            props.state.entries.map(entry => <ProductRow key={entry.url} entry={entry}
                                                         dispatch={props.dispatch} state={props.state} listType={props.listType}/>)}
        </ul>)
}

ProductTable.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  listType: PropTypes.string.isRequired,
  filter: PropTypes.bool,
  stores: PropTypes.array,
};

export default ProductTable;
