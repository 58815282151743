import React, { useContext, useEffect, useReducer, useState } from "react";
import { LIST_TYPE, TOAST_TYPE } from "../../constants";
import { GlobalReducerContext } from "../../contexts/GlobalReducerContext";
import useFetch from "../../hooks/UseFetch";
import { Actions as GlobalActions } from "../../reducers/GlobalReducer";
import { Actions, init, reducer } from "../../reducers/ShoppinglistReducers";
import CustomButton from "../CustomButton";
import Modal from "../Modal";
import ProductTable from "../ProductTable";
import AddEntry from "./AddProduct";


function ShoppingList(props) {
  const [shoppingListState, shoppingListDispatch] = useReducer(reducer, props, init);
  const fetch = useFetch();
  const [filter, setFilter] = useState(false);
  const {dispatch} = useContext(GlobalReducerContext);

  useEffect(() => {
    fetch._get("/store/", (data, status) => {
      if (status < 300) {
        shoppingListDispatch({
          type: Actions.STORES_FETCH_RETURNED,
          stores: data,
        });
      }
    });
    fetch._get("/category/", (data, status) =>{
      if (status === 200){
        shoppingListDispatch({
          type:Actions.CATEGORIES_FETCH_RETURNED,
          categories: data,
        });
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    shoppingListState.stores.find(store => store.selected === true) !== undefined ? setFilter(true) : setFilter(false);
  }, [shoppingListState.stores]);

  function handleFilterChange(index, checked) {
    shoppingListDispatch({
      type: Actions.STORES_UPDATE_SELECT,
      index: index,
      checked: checked
    })
  }

  useEffect(() => {
    fetch._get("/profiles/current/", (data, status) => {
      if (status === 200 && data[0].address === null) {
        dispatch({
          type: GlobalActions.DISPLAY_TOAST,
          toast: {
            type: TOAST_TYPE.WARNING,
            message: "Keine Adresse hinterlegt. Allgemeine Einkaufsliste wird verwendet"
          }
        });
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function deleteAll() {
    fetch._patch("/shoppinglist/markAllArticlesAsDeleted/", {}, (data, status) =>{
      console.log(data);
      if (status === 200){
        dispatch({
          type: GlobalActions.DISPLAY_TOAST,
          toast: {
            type: TOAST_TYPE.SUCCESS,
            message: "Alle Artikel gelöscht"
          }
        });
        shoppingListDispatch({
          type: Actions.SHOPPINGLIST_FETCH_RETURNED,
          entries: data
        })
      }
    })
  }

  return (
    <React.Fragment>
      <Modal title={"Läden auswählen"} styleDefault={true} id={"storeModal"} content={<form onSubmit={event => event.preventDefault()}>
        {shoppingListState.stores.map((store, index) => <div key={index}><input type="checkbox"
                                                                                className="w3-check"
                                                                                onChange={(e) => handleFilterChange(index, e.target.checked)}/>{store.name}
        </div>)}
        <CustomButton text={"Auswählen"}
                      onClick={() => document.getElementById("storeModal").style.display = 'none'}/>
      </form>}/>
      <Modal title="Alle Artikel löschen?" styleDefault={true} id={"confirmDeleteAllModal"} styleWarning={false}  content={<div className="w3-row w3-margin-bottom">
        <CustomButton text="ja" customClass={"w3-green w3-col m6"} onClick={() => {deleteAll(); document.getElementById("confirmDeleteAllModal").style.display='none'}}/>
        <CustomButton text="abbrechen" customClass={"w3-red w3-col m6"} onClick={() => document.getElementById("confirmDeleteAllModal").style.display='none'}/>
      </div>

      }   />
      <div className="w3-card">
        <AddEntry dispatch={shoppingListDispatch} state={shoppingListState}/></div>
      <div className="w3-card">
        <div className="w3-container w3-display-container w3-margin-top w3-grey">
          <div className="w3-display-right w3-margin-right" style={{paddingRight: "8px"}}>
            <img src={"/static/img/icons/filter_list_black_192x192.png"} width={"50px"} alt={"filter"}
                style={{cursor: "pointer", marginRight: "8px"}}
                onClick={() => document.getElementById("storeModal").style.display = 'block'}/>
            {/* ToDo: Prompt before deleting all */}
            <img src="/static/img/icons/trash_red.png" width={"40px"} alt="Delete All" 
                style={{cursor: "pointer"}}
                onClick={() => document.getElementById("confirmDeleteAllModal").style.display='block'}/>
          </div>
          <h2>Einkaufsliste</h2>
        </div>
        <ProductTable state={shoppingListState} dispatch={shoppingListDispatch}
                      listType={LIST_TYPE.SHOPPINGLIST} filter={filter}/>
      </div>
    </React.Fragment>
  );
}

export default ShoppingList;
