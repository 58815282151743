import React, {useEffect, useReducer, useState} from "react";
import useFetch from "../../hooks/UseFetch";
import AddCity from "./AddCity";
import PropTypes from "prop-types";
import {Actions, init, reducer} from "../../reducers/WeatherCityReducer";
import FetchError from "../FetchError";
import FetchLoading from "../FetchLoading";
import WeatherPanel from "./WeatherPanel";
import "../../styles/Icon.scss"
import Pagination from "../Pagination";
import LogRocket from "logrocket";
import useStateWithLocalStorage from "../../hooks/UseStateWithLocalStorage";
import {WEATHER, WEEKDAYS} from "../../constants";

function Weather(props) {
  const PAGE_SIZE = 15;
  const BASE_URL = "/weather/"
  const [showAddCity, setShowAddCity] = useState(false);
  const [state, dispatch] = useReducer(reducer, props, init);
  const [count, setCount] = useState(0);
  const [next, setNext] = useState("");
  const [date, setDate] = useState(new Date());
  const [storedUserPosition] = useStateWithLocalStorage("userPosition", null);
  const [userPosition] = useState(storedUserPosition);
  const [localWeather, setLocalWeather] = useState({});
  const fetch = useFetch();

  useEffect(() => {
    fetch._get(BASE_URL + "?page_size=" + PAGE_SIZE, (data, status) => {
      handleCallback(data, status);
      setCount(data.count);
      setNext(data.next || "");
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  /**
   * update the weather every 15 minutes
   */
  useEffect(() => {
    const fetchInterval = setInterval(() => {
      LogRocket.info("weather updated");
      fetchWeather();
    }, 1000 * 60 * 15);
    return () => {
      clearInterval(fetchInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function fetchWeather() {
    LogRocket.info("weather fetched")
    if (state.weatherCities.length > 0) {
      let url = "/weather/forecast/cities/?limit=4";
      state.weatherCities.map((city) => {
        return url += "&" + city.url + "=" + city.latitude + "," + city.longitude;
      });
      fetch._get(url, (data, status) => {
        if (status === 200) {
          dispatch({
            type: Actions.CITIES_FORECAST_RETURNED,
            forecastData: data
          })
        }
      })
    }
  }

  useEffect(() => {
    fetchWeather();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.weatherCities]);


  function handleCallback(data, status) {
    if (status === 200) {
      dispatch({
        type: Actions.CITIES_FETCH_RETURNED,
        cities: data.results
      });
    }
  }


  useEffect(() => {
    const timeInterval = setInterval(() => {
      setDate(new Date());
    }, 30 * 1000);
    return () => {
      clearInterval(timeInterval);
    }
  }, []);

  useEffect(() =>{
    if(userPosition === null){
      return;
    }
    fetch._get("/weather/current/city/?lat=" + userPosition.coords.latitude + "&lon=" + userPosition.coords.longitude, (data, status) => {
      if (status === 200) {
        let currentDate = new Date((data.current.dt) * 1000);
        let forecastList = [];
        for (let i = 0; i < 6; i++) {
          let date = new Date(data.forecast[i].dt * 1000);
          let sunriseDate = new Date((data.forecast[i].sunrise) * 1000);
          let sunsetDate = new Date((data.forecast[i].sunset) * 1000);
          let forecastEntry = {
            weekday: WEEKDAYS.SHORT[date.getDay()],
            icon: WEATHER.ICON[data.forecast[i].weather[0].id],
            description: data.forecast[i].weather[0].description,
            maxTemp: Math.ceil(data.forecast[i].temp.max),
            minTemp: Math.floor(data.forecast[i].temp.min),
            humidity: data.forecast[i].humidity,
            windSpeed: data.forecast[i].wind_speed,
            sunrise: sunriseDate.toLocaleTimeString([], {timeZone: data.timezone, hour: '2-digit', minute: '2-digit'}),
            sunset: sunsetDate.toLocaleTimeString([], {timeZone: data.timezone, hour: '2-digit', minute: '2-digit'}),
          };
          forecastList.push(forecastEntry);
        }
        setLocalWeather({
          name: userPosition.city,
          current : {
            description: data.current.weather[0].description,
            temp: data.current.temp,
            image: WEATHER.IMAGE[data.current.weather[0].id],
            time: currentDate.toLocaleTimeString([], {timeZone: data.timezone, hour: '2-digit', minute: '2-digit'}),
          },
          forecast: forecastList
        })
      }
    })
    }, [userPosition])

  return (
    <React.Fragment>
      <div className={"w3-right-align"}>
        <button style={{cursor: "pointer"}} className={"plusIcon"}
                onClick={() => setShowAddCity(prevState => !prevState)}><span
          style={showAddCity ? {transform: "rotate(-90deg)"} : null}/><span
          style={showAddCity ? {left: "50%", right: "50%", transform: "rotate(-90deg)"} : null}/>
        </button>
      </div>
      {fetch.error ?
        <FetchError error={fetch.error}/> :
        fetch.loading ? <FetchLoading/> :
          <React.Fragment>
            {showAddCity && <AddCity toggleMenu={setShowAddCity} globalDispatch={props.globalDispatch}
                                     dispatch={dispatch}/>}
            <div className={"dynamic-row "}>
              {storedUserPosition && <WeatherPanel city={localWeather} date={date} dispatch={dispatch} icon={"fa fa-xs fa-location-dot"}/> }
              {state.weatherCities.length > 0 ? state.weatherCities.map((city, index) =>
                <WeatherPanel city={city} key={index} date={date} dispatch={dispatch}/>
              ) : null}</div>
            <Pagination handleFetchDataCallback={handleCallback} count={count} initialNext={next} link={BASE_URL}
                        pageSize={PAGE_SIZE}/>
          </React.Fragment>}
    </React.Fragment>
  )
}

Weather.propTypes = {
  globalDispatch: PropTypes.func.isRequired,
}

export default Weather;