import React, {useContext, useEffect, useRef, useState} from "react";
import CustomInput from "./CustomInput";
import CustomSelect from "./CustomSelect";
import CustomButton from "./CustomButton";
import CustomTextarea from "./CustomTextarea";
import {UserContext} from "../contexts/UserContext";
import useFetch from "../hooks/UseFetch";
import {useFormik} from "formik";
import CustomFileInput from "./CustomFileInput";
import FetchError from "./FetchError";
import FetchLoading from "./FetchLoading";
import {Actions} from "../reducers/GlobalReducer";
import {GlobalReducerContext} from "../contexts/GlobalReducerContext";
import {TOAST_TYPE} from "../constants";

const validate = (values) => {
  const maxFileSizeMb = 10;
  const errors = {};
  if (!values.type) {
    errors.type = "Required"
  }

  if (!values.subject) {
    errors.subject = "Required"
  }

  if (!values.user) {
    errors.user = "Required"
  }

  for (let i = 0; i < values.attachment.length; i++) {
    if (!validateMimeType(values.attachment[i])) {
      errors.attachment = values.attachment[i].name + " ist keine Bilddatei"
    } else if (values.attachment[i].size / 1000 / 1000 > maxFileSizeMb) {
      errors.attachment = "Datei " + values.attachment[i].name + " ist größer als 10Mb"
    }
  }
  return errors;
};

function validateMimeType(attachment) {
  const types = ["image/png", "image/jpeg", "image/jpg", "image/tiff", "image/gif"];
  return types.find(type => type === attachment.type) !== undefined;
}

function RequestBug() {
  const {currentUser} = useContext(UserContext);
  const {dispatch} = useContext(GlobalReducerContext);
  const fetch = useFetch();
  const priorityList = {"4": "Niedrig", "3": "Normal", "2": "Hoch"};
  const fileInputRef = useRef();
  const [submitError, setSubmitError] = useState("");

  const form = useFormik({
    initialValues: {
      type: null,
      priority: "3",
      subject: "",
      user: "",
      message: "",
      attachment: [],
    },
    validate,
    onSubmit: (values) => handleFormSubmit(values),
  });

  useEffect(() => {
    if (!fetch.loading) {
      setSubmitError(fetch.error);
      form.setSubmitting(false);
    }
  }, [fetch.error]);

  useEffect(() => {
    form.setFieldValue("user", currentUser.first_name, true);
  }, [currentUser.first_name])

  function handleRequestSuccess(type) {
    dispatch({
      type: Actions.DISPLAY_TOAST,
      toast: {
        type: TOAST_TYPE.SUCCESS,
        message: type + " gesendet!"
      }
    });
    form.resetForm({
      values: {
        type: null,
        priority: "3",
        subject: "",
        user: currentUser.first_name,
        message: "",
        attachment: [],
      }
    });
    form.setSubmitting(false);
    fileInputRef.current.value = "";
  }

  function sendRequestWithoutFile(values) {
    fetch._post("/ticket/", {
      "type": values.type,
      "subject": values.subject,
      "message": values.message,
      "user": values.user,
      "priority": values.priority,
    }, () => {
      handleRequestSuccess(values.type);
    });
  }

  function sendRequestWithFile(values) {
    let formData = new FormData();
    formData.append("type", values.type);
    formData.append("subject", values.subject);
    formData.append("message", values.message);
    formData.append("user", values.user);
    formData.append("priority", values.priority);
    formData.append("attachmentSize", values.attachment.length);
    for (let i = 0; i < values.attachment.length; i++) {
      formData.append("attachment[" + i + "]", values.attachment[i]);
    }
    fetch._postFile("/ticketAttachment/", formData, () => {
      handleRequestSuccess(values.type);
    });
  }

  function handleFormSubmit(values) {
    setSubmitError("");
    form.setSubmitting(true);
    if (values.attachment.length > 0) {
      sendRequestWithFile(values);
    } else {
      sendRequestWithoutFile(values);
    }
  }

  return (
    <React.Fragment>
      {submitError && <FetchError error={submitError}/>}
      {fetch.loading && <FetchLoading/>}
      <div className="w3-card">
        <div className="w3-container w3-grey">
          <h3>Create Request/Bug</h3>
        </div>
        <form className="w3-container" id="requestForm" onSubmit={form.handleSubmit}>
          <CustomSelect
            text="Art*"
            id="type"
            name="type"
            defaultValue="id"
            value={form.values.type}
            options={{Bug: "Bug", Request: "Request", id: "Art*"}}
            onChange={form.handleChange}
            error={form.errors.type}
            touched={form.touched.type}/>

          <CustomInput
            text="Betreff*"
            id="subject"
            name="subject"
            type="text"
            value={form.values.subject}
            onChange={form.handleChange}
            error={form.errors.subject}
            touched={form.touched.subject}/>

          <CustomTextarea
            text="Beschreibung"
            id="message"
            name="message"
            resize="vertical"
            width="100%"
            height="200px"
            value={form.values.message}
            onChange={form.handleChange}
            error={form.errors.message}
            touched={form.touched.message}
          />

          <CustomSelect
            text="Priorität"
            options={priorityList}
            id="priority"
            name="priority"
            defaultValue="3"
            defaultValueCanBeSelected={true}
            value={form.values.priority}
            onChange={form.handleChange}
            error={form.errors.priority}
            touched={form.touched.priority}
          />

          <CustomInput
            text="Ersteller*"
            type="text"
            id="user"
            name="user"
            value={form.values.user}
            onChange={form.handleChange}
            error={form.errors.user}
            touched={form.touched.user}/>

          <CustomFileInput
            type="file"
            id="attachment[]"
            name="attachment[]"
            text="Anhang (max. 10Mb pro Datei)"
            ref={fileInputRef}
            onChange={e => {
              form.setFieldValue("attachment", e.currentTarget.files)
            }}
            error={form.errors.attachment}
            touched={form.touched.attachment}
          />

          <CustomButton text="Absenden" disabled={fetch.loading}
                        customClass={fetch.loading ? "w3-grey" : null}/>
        </form>
      </div>
    </React.Fragment>
  );
}

export default RequestBug;
