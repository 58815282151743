import React, {useState} from "react";
import CustomInput from "./CustomInput";
import {useFormik} from "formik";
import CustomButton from "./CustomButton";
import useFetch from "../hooks/UseFetch";
import {Navigate, useParams} from "react-router-dom";
import {API_VERSION, TOAST_TYPE} from "../constants";
import FetchLoading from "./FetchLoading";
import FetchError from "./FetchError";
import PropTypes from "prop-types";
import {Actions as GlobalActions} from "../reducers/GlobalReducer";

const validate = values => {
  const errors = {};
  const MIN_PASSWORD_LENGTH = 10;
  const MAX_PASSWORD_LENGTH = 255;

  if (!values.password) {
    errors.password = "Passwort eingeben!"
  } else if (values.password.length < MIN_PASSWORD_LENGTH) {
    errors.password = "Passwort muss mindestens " + MIN_PASSWORD_LENGTH + " Zeichen haben!";
  } else if (values.password.length > MAX_PASSWORD_LENGTH) {
    errors.password = "Passwort darf nicht länger als " + MAX_PASSWORD_LENGTH + " Zeichen sein!";
  }
  if (values.password !== values.passwordRepeat) {
    errors.passwordRepeat = "Passwörter stimmen nicht überein!";
  }
  return errors;
};

function Registration(props) {
  const fetch = useFetch();
  const {token} = useParams();
  const [error, setError] = useState([]);
  const [success, setSuccess] = useState(false);

  function handleSubmit(values) {
    setError([]);
    fetch._patch(API_VERSION + "/registration/" + token + "/", {"password": values.password}, (data, status) => {
      if (status < 300) {
        props.globalDispatch({
          type: GlobalActions.DISPLAY_TOAST,
          toast:{
            type: TOAST_TYPE.SUCCESS,
            message: "Passwort erfolgreich gesetzt!"
          }
        });
        setSuccess(true);
      } else {
          setError(Object.values(data));
      }


    });
  }

  const form = useFormik({
      initialValues: {
        password: "",
        passwordRepeat: "",
      },
      validate,
      onSubmit: values => handleSubmit(values)
    }
  );
  return (
    <React.Fragment>
      {success ? <Navigate to={"/"} replace={true}/> :
        fetch.loading ? <FetchLoading/> :
          (fetch.error !== "" || error.length > 0) ? <FetchError error={fetch.error} errorArray={error}/> : ""}
      <div style={{display: "flex", justifyContent: "center"}}>
        <div className="w3-half w3-card-4">
          <div className="w3-grey w3-container"><h3>Passwort setzen</h3></div>
          <form className="w3-container" onSubmit={form.handleSubmit}>
            <CustomInput type={"password"} id={"password"} onChange={form.handleChange} value={form.values.password}
                         touched={form.touched.password} text={"Passwort"} error={form.errors.password}
                         name={"password"}/>
            <CustomInput type={"password"} id={"passwordRepeat"} onChange={form.handleChange}
                         value={form.values.passwordRepeat} touched={form.touched.passwordRepeat}
                         text={"Passwort wiederholen"} error={form.errors.passwordRepeat} name={"passwordRepeat"}/>
            <CustomButton text={"Absenden"} disabled={fetch.loading}/>
          </form>
        </div>

      </div>
    </React.Fragment>
  );
}

Registration.propTypes = {
  globalDispatch: PropTypes.func.isRequired,
};

export default Registration;