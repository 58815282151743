import React, { useContext, useEffect } from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import "../../styles/Icon.scss"
import Modal from "../Modal";
import { useGoogleLogin } from "@react-oauth/google";
import CustomButton from "../CustomButton";
import useFetch from "../../hooks/UseFetch";
import CustomInput from "../CustomInput";
import { useFormik } from "formik";
import { GlobalReducerContext } from "../../contexts/GlobalReducerContext";
import { CALENDAR_SHARING_OPTIONS, TOAST_TYPE } from "../../constants";
import { Actions } from "../../reducers/GlobalReducer";
import { UserContext } from "../../contexts/UserContext";

function ProfileCalendar(){

    const [calendars, setCalendars] = useState([])
    const [googleUserToken, setGoogleUserToken] = useState({});
    const fetch = useFetch();    
    //ToDo: Move to DB/backend
    const SCOPES = 'https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events.readonly';

    const googleLogin = useGoogleLogin({
        onSuccess: tokenResponse => {
            if (googleUserToken.token && !googleUserToken.valid){
                fetch._patch("/my-calendar/googleusertoken/update_token/", ({"code":tokenResponse.code}), (_,status) =>{
                    if(status === 200){
                        setGoogleUserToken({... googleUserToken, valid: true})
                    }
                })
            }else{
                fetch._post("/my-calendar/googleusertoken/", ({"code":tokenResponse.code}), (_, status) => { 
                    if (status === 201){
                        setGoogleUserToken({token: true, valid: true})
                    }
                })
            }            
        },
        flow: 'auth-code',
        scope: SCOPES,
        onError: error => console.log(error)
    })

    const updatePrivacyStateForCalendar = (calendarUrl, newPrivacyState, newSharedWith = []) => {
        setCalendars(prevCalendars => prevCalendars.map(calendar => {
            return calendar.url === calendarUrl ? { ...calendar, privacyStatus: newPrivacyState, sharedWith: newSharedWith} : calendar
        }));       
    };

    useEffect(() =>{
        fetch._get("/my-calendar/googleusertoken/", (data, status) => {
            if(status == 200){
                setGoogleUserToken({token: data['token'], valid: data['token_valid']});
            } 
        }) 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function fetchCalendarsForUsers(){
        fetch._get("/my-calendar/", (data,status) =>{
            if(status == 200){
                let calendarList = []
                data.forEach(entry => {
                    let calendar = {};
                    calendar.url = entry.url;
                    calendar.name = entry.name;
                    calendar.privacyStatus = entry.privacy_status;
                    calendar.sharedWith = entry.shared_with;
                    calendarList.push(calendar)
                });
                setCalendars(calendarList);
            }
        })
    }

    useEffect(() =>{
        if(googleUserToken.token && googleUserToken.valid){
            document.getElementById("addCalendarModal").style.display = "none";
        }
        fetchCalendarsForUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [googleUserToken])

    return (
        <React.Fragment>
        <div className="w3-margin">
            <div className="w3-row">            
                <h4 className="w3-col s11" style={{paddingTop: "10px"}}>Kalenderübersicht</h4>
                <div className="w3-col s1 w3-right-align">
                    <button style={{cursor: "pointer"}} className={"plusIcon"}
                                onClick={() => document.getElementById("addCalendarModal").style.display = "block"}>
                                    <span style={{transform: "rotate(-90deg)"}}/>
                                    <span style={{transform: "rotate(-90deg)"}}/>
                    </button>
                </div>
                {(googleUserToken.token === true && googleUserToken.valid === false) && <span>Nicht mehr bei Google angemeldet. Für weiteren Zugriff auf die Google Kalender bitte erneut <span style={{cursor:"pointer", textDecorationLine:"underline"}} onClick={() => googleLogin()}>anmelden</span></span>}
            </div>    
            {calendars.length === 0 ? "noch keine Kalender hinzugefügt":
            calendars.map((calendar, index)=>{
                return (<React.Fragment key={index}>
                            <CalendarEntry calendar={calendar} id={index} updatePrivacyState={updatePrivacyStateForCalendar} />
                            <SharingOptionsModal id={index} calendarName={calendar.name} calendarUrl={calendar.url} sharedWith={calendar.sharedWith} updatePrivacyStateForCalendar={updatePrivacyStateForCalendar} />
                        </React.Fragment>)
            })
        }
        </div>
        <br />
        <CalendarModal googleUserToken={googleUserToken} googleLogin={googleLogin} setCalendars={setCalendars}/>
        </React.Fragment>
    );
}

function CalendarModal({googleLogin, googleUserToken, setCalendars}){
    
    const [showCalendarForm, setShowCalendarForm] = useState(false);
    const fetch = useFetch();

    const calendarForm = useFormik({
        initialValues: {
            url: "",
            username: "",
            password: "",
            name: "",
        },
        validate: values => validateCalendarForm(values),
        onSubmit: values => handleFormSubmit(values)
    });

    function handleFormSubmit(values){
        fetch._post("/my-calendar/", {"calendar_url" : values.url, "username": values.username, "password": values.password, "name": values.name}, (data, status)=>{
            if (status == 201){
                document.getElementById("addCalendarModal").style.display = "none";
                let calendar = {};
                calendar.url = data.url;
                calendar.name = data.name;
                calendar.privacyStatus = data.privacy_status;
                calendar.sharedWith = data.shared_with;
                setCalendars(prevCalendars => [...prevCalendars, calendar]);
                calendarForm.resetForm();
            }
            //ToDo: error handling
        })
    }

    return(
        <Modal title={"Kalender hinzufügen"} styleWarning={false} id={"addCalendarModal"} styleDefault={true} content={
            <React.Fragment>                
                <CustomButton onClick={() => googleLogin()} text="Google Calendar" disabled={googleUserToken.token == true}/>
                <CustomButton onClick={() => { setShowCalendarForm(oldValue => !oldValue)}} text="Kalender mit URL"/>
                {showCalendarForm && <form id="calendar-form" onSubmit={e => e.preventDefault()} >
                    <CustomInput id="name" name="name" autocomplete={"false"} required={true} text={"Kalender Name"} type="text" value={calendarForm.values.name} onChange={calendarForm.handleChange} touched={calendarForm.touched.name} error={calendarForm.errors.name} />
                    <CustomInput id="url" name="url" autocomplete={"false"} required={true} text={"Kalender URL"} type="text" value={calendarForm.values.url} onChange={calendarForm.handleChange} touched={calendarForm.touched.url} error={calendarForm.errors.url} />
                    <CustomInput id="username" name="username" autocomplete={"false"} required={true} text={"Username"} type="text" value={calendarForm.values.username} onChange={calendarForm.handleChange} touched={calendarForm.touched.username} error={calendarForm.errors.username} />
                    <CustomInput id="password" name="password" autocomplete={"false"} required={true} text={"Password"} type="password" value={calendarForm.values.password} onChange={calendarForm.handleChange} touched={calendarForm.touched.password} error={calendarForm.errors.password}/>
                    <CustomButton text="Hinzufügen" onClick={calendarForm.submitForm} />
                </form>}
            </React.Fragment>
        }/>
    )
}

const validateCalendarForm = (values) => {
    const errors = {};
    if(!values.url){
        errors.url = "Required";
    }
    if(!values.username){
        errors.username = "Required";
    }
    if(!values.password){
        errors.password = "Required";
    }
    if(!values.name){
        errors.name = "Required";
    }
    return errors;
}

CalendarModal.propTypes = {
    googleLogin : PropTypes.func.isRequired,
    googleUserToken: PropTypes.object.isRequired,
    setCalendars: PropTypes.func.isRequired,
}

function CalendarEntry({calendar, id, updatePrivacyState}){
    //ToDo: Pagination for more then 10
    const privacyState = calendar.privacyStatus;
    const [showMenu, setShowMenu] = useState(false);

    //ToDO: Delete Function
    return(
        <div>
            <span className="w3-bar w3-black w3-row">
                <span className="w3-bar-item w3-col s2">
                    {privacyState==1 ? 
                        <div className="w3-bar-item w3-tooltip w3-green">
                            <i className="fa fa-unlock" />
                            <span className="w3-text w3-animate-opacity w3-grey" style={{position: "absolute", left: "0px", top: "-28px", whiteSpace: "nowrap"}}>Für ausgewählte Personen sichtbar</span>
                        </div> :
                    privacyState == 2 ?
                        <div className="w3-bar-item w3-tooltip w3-blue">
                            <i className="fa fa-shield-halved" />
                            <span className="w3-text w3-animate-opacity w3-grey" style={{position: "absolute", left: "0px", top: "-28px", whiteSpace: "nowrap"}}>Nur für dich sichtbar</span>
                        </div> :
                        <div className="w3-bar-item w3-tooltip w3-red">
                        <i className="fa fa-lock"/>
                        <span className="w3-text w3-animate-opacity w3-grey" style={{position: "absolute", left: "0px", top: "-28px", whiteSpace: "nowrap"}}>Deaktiviert</span>
                        </div>
                    }
                </span>
                <span className="w3-bar-item w3-text-black w3-col s8">
                    {calendar.name}
                </span>
                <span className="w3-bar-item w3-text-black s2 w3-dropdown-click" onClick={() => setShowMenu(prevState => !prevState)} >
                    <i className="fa-solid fa-ellipsis-vertical w3-margin-right dropdown-click" style={{color: "w3-black", cursor:"pointer"}} />
                    {/* on Mouseout: timer to 2 seconds, afterwards hide dropdown. If mouse in, cancel timeout */}
                    {showMenu && <Dropdown privacyState={privacyState} updatePrivacyState={updatePrivacyState} calendarUrl={calendar.url} id={id} sharedWith={calendar.shared_with}/> }
                </span>
                
            </span>
        </div>

    )
}

CalendarEntry.propTypes = {
    calendar : PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    updatePrivacyState: PropTypes.func.isRequired,
}

function Dropdown({privacyState, updatePrivacyState, calendarUrl, id}){    
    const fetch = useFetch();


    function updatePrivacy(newState){
        if(privacyState !== newState){
            fetch._patch(calendarUrl, {"privacy_status": newState}, (data, status)=>{
                if (status < 300){
                    updatePrivacyState(calendarUrl, data.privacy_status);
                }
                //ToDo: Error handling
            });
        }
    }


    return (
        <React.Fragment>
            <div className="w3-dropdown-content w3-bar-block w3-card-4 w3-show">
                {(privacyState !== CALENDAR_SHARING_OPTIONS.PRIVATE) && <div onClick={() => updatePrivacy(CALENDAR_SHARING_OPTIONS.PRIVATE)} style={{cursor:"pointer"}} className={"w3-bar-item w3-button"}>Privat</div>}
                {(privacyState !== CALENDAR_SHARING_OPTIONS.DISABLED) && <div onClick={() => updatePrivacy(CALENDAR_SHARING_OPTIONS.DISABLED)} style={{cursor:"pointer"}} className="w3-bar-item w3-button">Deaktivieren</div>}
                <div className="w3-bar-item w3-button" onClick={() => {document.getElementById(`calendarSharingOptionsModal${id}`).style.display='block';}}>Freigabeoptionen</div>
            </div>
            

        </React.Fragment>


    )
}

Dropdown.propTypes = {
    privacyState : PropTypes.number.isRequired,
    updatePrivacyState: PropTypes.func.isRequired,
    calendarUrl: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
}

function SharingOptionsModal({id, calendarName, calendarUrl, sharedWith, updatePrivacyStateForCalendar}){    
    const {state, dispatch} = useContext(GlobalReducerContext);
    const {currentUser} = useContext(UserContext);
    const fetch = useFetch();
    const initialFormValues = {}
    
    state.users.forEach((user, index) =>{
        if (user.url === currentUser.url){
            return;
        }
        initialFormValues[`user_${index}_first_name`] = user.first_name || '';
        initialFormValues[`user_${index}_last_name`] = user.last_name || '';
        initialFormValues[`user_${index}_checked`] = sharedWith.find(sharedUser => sharedUser.user.url === user.url) ? true : false; 
        initialFormValues[`user_${index}_url`] = user.url;
    });

    const form = useFormik({
        initialValues: initialFormValues,
        enableReinitialize: true,
        // validate: values => 
        onSubmit: values => submitForm(values),
    });

    function submitForm(values){
        const ids = [];   
        Object.keys(values).map(key => {
            if (key.includes('_checked')){
                const userId = key.split('_')[1]; // Extracting the user ID from the key
                const url = form.values[`user_${userId}_url`];
                const isChecked = form.values[key];
                if (isChecked){
                    ids.push(url);
                }
            }
        });
        fetch._post(`${calendarUrl}share/`, {"user_urls": ids}, (data, status)=>{
            if (status === 201){
                updatePrivacyStateForCalendar(calendarUrl, CALENDAR_SHARING_OPTIONS.SHARED, data.shared_with);
            } else if(status === 202){ 
                updatePrivacyStateForCalendar(calendarUrl, CALENDAR_SHARING_OPTIONS.PRIVATE);
            }
            else if (status === 406){
                dispatch({
                    type: Actions.DISPLAY_TOAST,
                    toast: {
                        type: TOAST_TYPE.WARNING,
                        message: "Kalender wurde nicht mit weiteren Nutzern geteilt, alte Option bleibt vorhanden."
                    }
                })

            }
            form.resetForm();            
            document.getElementById(`calendarSharingOptionsModal${id}`).style.display='none';
            //ToDo: Error handling
        });
    }

    return (        
        <Modal id={`calendarSharingOptionsModal${id}`} title={`Freigabeoptionen für Kalender ${calendarName}`} styleDefault={true} content={
            <React.Fragment>
                <ul className="w3-ul w3-margin-top w3-hoverable">
                    {Object.keys(initialFormValues).map(key => {
                        if (key.includes('_checked')){
                            const userId = key.split('_')[1]; // Extracting the user ID from the key
                            const firstName = form.values[`user_${userId}_first_name`];
                            const lastName = form.values[`user_${userId}_last_name`];
                            const isChecked = form.values[key];
                            return (
                                <li key={key} className="w3-bar">
                                    <input className="w3-check w3-bar-item" type="checkbox" name={key} checked={isChecked} onChange={form.handleChange}></input>
                                    <label className="w3-bar-item">{firstName} {lastName}</label> 
                                </li>
                            )
                        }
                    })}
                </ul>
                    <CustomButton text="Speichern" onClick={form.handleSubmit}/>
            </React.Fragment>
        }/>
    )
}

SharingOptionsModal.propTypes = {
    id: PropTypes.number.isRequired,
    calendarName: PropTypes.string.isRequired,
    calendarUrl: PropTypes.string.isRequired,
    sharedWith: PropTypes.array,
    updatePrivacyStateForCalendar: PropTypes.func.isRequired,
}

export default ProfileCalendar;