export const Actions = {
    AVAILABLE_CALENDARS : "AVAILABLE_CALENDARS",
    TOGGLE_USER_ENABLED: "TOGGLE_USER_ENABLED",
    ADD_EVENTS_TO_CALENDAR: "ADD_EVENTS_TO_CALENDAR",
}

export const init = () => {
  return {
    availableCalendars: [],
  }
};

export const reducer = (state, action) => {
  switch(action.type) {
    case "AVAILABLE_CALENDARS":
      return {
        ...state,
        availableCalendars: [...state.availableCalendars, ...action.availableCalendars]
      }
    case "TOGGLE_USER_ENABLED": {
      let tmpCalendars = [...state.availableCalendars];
      let calendarToToggle = tmpCalendars.find(user => user.url == action.url);
      if(calendarToToggle !== undefined){
        calendarToToggle.active = !calendarToToggle.active;
      }
      return{
        ...state,
        availableCalendars: tmpCalendars,
      }
    }
    case "ADD_EVENTS_TO_CALENDAR":
      return{
        ...state,
        availableCalendars: state.availableCalendars.map(user => {
          const updatedCalendars = user.calendars.map(calendar => {
            if (calendar.url == action.url){
              // Update the specific calendar with new events
              return {
                ...calendar,
                events: action.events,  
              }
            }
            return calendar;
          });

          // Return the user with updated calendars
          return {
            ... user,
            calendars: updatedCalendars,
          };
        }),
    }
    default:
      return {
        ...state
      }
  }
}