import React from "react";
import PropTypes from "prop-types"

function FilterListElement(props) {
  if (props.index === props.activeElement){
    return (    
      <li value={props.value} style={{cursor: "pointer"}} className={"w3-light-grey w3-hover-grey"}
          onMouseDown={() => props.setValue(props.displayText)}>{props.displayText}</li>
    )
  }else{
    return (    
      <li value={props.value} style={{cursor: "pointer"}} className={"w3-hover-grey"}
          onMouseDown={() => props.setValue(props.displayText)}>{props.displayText}</li>
    )
  }
}

FilterListElement.propTypes = {
  value: PropTypes.string.isRequired,
  displayText: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  activeElement: PropTypes.number,
  index: PropTypes.number.isRequired,
};

export default FilterListElement;