export const calendarView = {
    MONTH : "dayGridMonth",
    WEEK : "timeGridWeek", 
    DAY: "timeGridDay", 
    LIST: "listWeek"
}



export function updateWeekTextNumber(viewInfo) {
    const weekNumber = getCalendarWeekNumberForDate(viewInfo.start);
    const weekText = viewInfo.view.dateEnv.weekText;
    const timegrid = document.querySelector('.fc-timegrid-axis');
    if (timegrid) {
        timegrid.textContent = `${weekText} ${weekNumber}`;
    }
}

export function goToDayView(viewInfo) {
    viewInfo.view.calendar.changeView(calendarView.DAY, viewInfo.dateStr);
}

export function createEvent (user, event) {
    const allDayEvent = isAllDayEvent(event.start, event.end);
    return {
        id: btoa(unescape(encodeURIComponent(`${user.name}${event.title}${event.start}`))),
        title: event.title,
        start: event.start,
        end: event.end,
        location: event.location || "",
        color: user.color,
        editable: false,
        source: user.url,
        allDay: allDayEvent,
    };
}

function getCalendarWeekNumberForDate(date){
    const januaryFirst = new Date(date.getFullYear(),0,1);
    const weekNumber = Math.ceil(((date - januaryFirst)/86400000 + januaryFirst.getDay() +1) / 7)
    return weekNumber;
}

function isAllDayEvent (startDate, endDate) {
    const diffInMilliseconds = Math.abs(new Date(startDate) - new Date(endDate));
    const minutesInDay = 24*60;

    return Math.round(diffInMilliseconds / (1000 * 60)) % minutesInDay === 0;
}