import React from "react";
import PropTypes from 'prop-types'

function CustomIconButton(props) {
  const maxSize = "30px";

  return (
    <div className={(props.round ? "w3-circle": "")+" btn w3-blue "+ (props.class !== undefined? props.class : "")} style={props.customStyle} >
      <img
        alt={props.altText}
        src={"/static/img/icons/" + props.icon}
        onClick={props.onClick}
        className="w3-image margin-4"
        style={{maxWidth: maxSize, maxHeight: maxSize}}
      />
    </div>
  )
}

CustomIconButton.propTypes = {
  altText: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  class: PropTypes.string,
  round: PropTypes.bool,
  customStyle: PropTypes.object,
};

export default CustomIconButton;