import React, {useEffect, useState} from "react";
import PropTypes from "prop-types"
import CustomInputError from "./CustomInputError";

function CustomSelect(props) {
  const [labelVisibility, setLabelVisibility] = useState("none");
  useEffect(() => {
    if (props.value === null && labelVisibility === "block") {
      setLabelVisibility("none");
      if (typeof props.decreaseContainerHeight === "function") {
        props.decreaseContainerHeight();
      }
    } else if (props.value !== null && labelVisibility === "none") {
      if (typeof props.increaseContainerHeight === "function") {
        props.increaseContainerHeight();
      }
      setLabelVisibility("block");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  function options() {
    let options = [];
    for (let [key, value] of Object.entries(props.options)) {
      if (key === props.defaultValue) {
        if (props.defaultValueCanBeSelected) {
          options.push(
            <option value={key} key={key}>
              {value}
            </option>
          );
        }
        options.push(
          <option disabled hidden value={key} key={"hidden-"+key}>
            {value}
          </option>
        );
      } else {
        options.push(<option value={key} key={key}>{value}</option>);
      }
    }
    return options;
  }

  return (
    <p>
      <select
        className="w3-select w3-border-blue"
        id={props.id}
        name={props.name}
        value={props.value !== null ? props.value : props.defaultValue}
        required={props.required}
        onChange={(e) => {
          props.onChange(e);
        }}
      >
        {options()}
      </select>
      <label style={{display: labelVisibility}} htmlFor={props.id}
             className="w3-text-gray margin-left-6">{props.text}</label>
      {props.touched && props.error ? <CustomInputError message={props.error}/> : null}
    </p>
  );
}

CustomSelect.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
  defaultValueCanBeSelected: PropTypes.bool,
  value: PropTypes.string,
  options: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  decreaseContainerHeight: PropTypes.func,
  increaseContainerHeight: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
};

export default CustomSelect;
