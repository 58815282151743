import React, {useEffect, useReducer, useState} from "react";
import AddWebcam from "./AddWebcam";
import Webcam from "./Webcam";
import FetchLoading from "../FetchLoading";
import FetchError from "../FetchError";
import {Actions, init, reducer} from "../../reducers/WebcamReducers";
import useFetch from "../../hooks/UseFetch";
import Pagination from "../Pagination";

function WebcamList() {
  const PAGE_SIZE = 15;
  const BASE_URL = "/webcams/";
  const [state, dispatch] = useReducer(reducer, null, init);
  const [showAddWebcam, setShowAddWebcam] = useState(false);
  const [count, setCount] = useState(0);
  const [next, setNext] = useState("");
  const fetch = useFetch();

  useEffect(() => {
    fetch._get(BASE_URL + "?page_size=" + PAGE_SIZE, (data, status) => {
      handleCallback(data, status);
      setNext(data.next);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function handleCallback(data, status) {
    if (status < 300) {
      dispatch({
        type: Actions.WEBCAMS_FETCH_RETURNED,
        webcams: data.results
      });
      setCount(data.count);
    }
  }

  return (
    <div>
      <div className={"w3-right-align"}>
        <button style={{cursor: "pointer"}} className={"plusIcon"}
                onClick={() => setShowAddWebcam(prevState => !prevState)}><span
          style={showAddWebcam ? {transform: "rotate(-90deg)"} : null}/><span
          style={showAddWebcam ? {left: "50%", right: "50%", transform: "rotate(-90deg)"} : null}/>
        </button>
      </div>
      {showAddWebcam ? <AddWebcam dispatch={dispatch} setShowAddWebcam={setShowAddWebcam}/> :
        fetch.loading ? <FetchLoading/> :
          (fetch.error !== "") ? <FetchError error={fetch.error}/> :
            <div className="w3-container dynamic-row dynamic-row-bottom">
              {(state.webcams.length > 0) ?
                state.webcams.map(
                  (webcam, index) => (
                    <Webcam name={webcam.name} webcam_url={webcam.webcam_url} url={webcam.url} key={index}
                            dispatch={dispatch}/>
                  )
                )
                :
                <div>
                  <div>Keine Einträge vorhanden</div>
                </div>}
            </div>
      }
      <Pagination pageSize={PAGE_SIZE} handleFetchDataCallback={handleCallback} count={count} initialNext={next}
                  link={BASE_URL}/>
    </div>
  );
}

export default WebcamList;
