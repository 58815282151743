import React, {Component} from "react";

class NotFound extends Component {
  state = {};

  render() {
    return (
      <div className="w3-row">
        <div className={"w3-center"}>
          <h2>404 Seite nicht gefunden</h2>
          <span>
            This is an early version of the site. Please come back later.
            <br/>
            Upcomming features:
            <br/>
          </span>
        </div>
        <div>
          <div className="w3-quarter w3-hide-small">&nbsp;</div>
          <ul className="w3-ul w3-half">
            <li style={{textDecoration: "line-through"}}>Hinzufügen neuer Nutzer</li>
            <li style={{textDecoration: "line-through"}}>Funktionalität des Request Formulars</li>
            <li style={{textDecoration: "line-through"}}>kein Ladefehler bei Neuladen</li>
            <li style={{textDecoration: "line-through"}}>Passwort zurücksetzen</li>
            <li style={{textDecoration: "line-through"}}>Wetter auf Startseite</li>
            <li style={{textDecoration: "line-through"}}>begrenztes Laden der Webcams</li>
            <li style={{textDecoration: "line-through"}}>Persönliches Profil</li>
            <li style={{textDecoration: "line-through"}}>Shoppinglist abhängig von Adresse</li>
            <li style={{textDecoration: "line-through"}}>Wetter für beliebige Orte</li>
            <li>Integration von Google Kalendern</li>
          </ul>
        </div>

      </div>
    )
      ;
  }
}

export default NotFound;
