import React, {useContext} from "react";
import {Actions} from "../reducers/ShoppinglistReducers";
import PropTypes from "prop-types";
import CustomInput from "./CustomInput";
import CustomSelect from "./CustomSelect";
import useFetch from "../hooks/UseFetch";
import {GlobalReducerContext} from "../contexts/GlobalReducerContext";

function ShoppinglistEntryDisplay(props) {
  const fetch = useFetch();

  /**
   * set the article to bought/not bought
   */
  function handleBought() {
    props.setBought(!props.bought);
    fetch._patch(props.entry.url + "buy/", ({'is_bought': !props.bought}), () => {
      props.dispatch({
        type: Actions.SHOPPINGLIST_BUY,
        bought: !props.bought,
        url: props.entry.url,
      });
    });

  }

  return (
    <div style={{cursor: "pointer"}} onClick={handleBought}>
      <div className="w3-bar-item" style={{width: "100%", paddingRight:"108px"}}>
        <div style={{textDecoration: props.bought ? "line-through" : "none"}}>
          <div>{props.entry.amount === "1" ? "" : props.entry.amount+ " "}
            {props.entry.name}</div>
          {props.entry.store ?
            <div className="w3-small w3-hide-medium w3-hide-small"><img src="/static/img/icons/store_black.png" alt={"store"}
                                                                        width={"25px"}/> {props.entry.store}</div> : ""}
        </div>
        {props.userName && <span className="w3-small">für {props.userName}</span>}
      </div>
    </div>
  );
}

function ShoppinglistEntryEdit(props) {
  const {state} = useContext(GlobalReducerContext);
  const userList = user => {
    let tmpList = {};
    for (let userEntry of user) {
      tmpList[userEntry.url] = userEntry.first_name
    }
    return tmpList;
  };

  return (
    <div className="w3-threequarter">
      <div className="w3-bar-item" style={{width: "100%"}}>
            <span>
              <CustomInput
                type={"text"}
                id={"amount"}
                name={"amount"}
                text={"Menge*"}
                value={props.form.values.amount}
                onChange={props.form.handleChange}
                error={props.form.errors.amount}
                touched={props.form.touched.amount}
              />
              <CustomInput
                type="text"
                id={"name"}
                name={"name"}
                value={props.form.values.name}
                text={"Name*"}
                onChange={props.form.handleChange}
                error={props.form.errors.name}
                touched={props.form.touched.name}
              />
              <CustomInput
                type="text"
                id={"store"}
                name={"store"}
                value={props.form.values.store}
                text={"Laden"}
                onChange={props.form.handleChange}
                error={props.form.errors.store}
                touched={props.form.touched.store}
              />
              <CustomSelect id="user" name="user" value={props.form.values.user} text={"Für*"}
                            options={userList(state.users)}
                            defaultValue={props.entry.user} onChange={props.form.handleChange}
                            error={props.form.errors.user}
                            touched={props.form.touched.user}/>
            </span>
      </div>
    </div>
  )
}

function WishlistEntryDisplay(props) {

  return (
    <div>
      <div className="w3-bar-item" style={{width: "100%", paddingRight:"108px"}}>
            <span>
              {props.entry.hyperlink !== "" ?
                <a href={props.entry.hyperlink} target={"_blank"} rel={"noreferrer"}>{props.entry.name}</a> :
                props.entry.name}
              </span><br/>
        {props.userName && <span className="w3-small">für {props.userName}</span>}
      </div>
    </div>
  );
}

function WishlistEntryEdit(props) {
  const {state} = useContext(GlobalReducerContext);
  const userList = user => {
    let tmpList = {};
    for (let userEntry of user) {
      tmpList[userEntry.url] = userEntry.first_name
    }
    return tmpList;
  };

  return (
    <div className="w3-threequarter">
      <div className="w3-bar-item" style={{width: "100%"}}>
            <span>
              <CustomInput
                type="text"
                id={"name"}
                name={"name"}
                value={props.form.values.name}
                text={"Name*"}
                onChange={props.form.handleChange}
                error={props.form.errors.name}
                touched={props.form.touched.name}
              />
              <CustomInput type={"url"} id={"link"} onChange={props.form.handleChange} value={props.form.values.link}
                           name={"link"} error={props.form.error} touched={props.form.touched} text={"Link"}/>
              <CustomSelect id="user" name="user" value={props.form.values.user} text={"Für*"}
                            options={userList(state.users)}
                            defaultValue={props.entry.user} onChange={props.form.handleChange}
                            error={props.form.errors.user}
                            touched={props.form.touched.user}/>
            </span>
      </div>
    </div>
  );
}

ShoppinglistEntryDisplay.propTypes = {
  entry: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  bought: PropTypes.bool.isRequired,
  setBought: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired
};

ShoppinglistEntryEdit.propTypes = {
  entry: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

WishlistEntryDisplay.propTypes = {
  entry: PropTypes.object.isRequired,
  userName: PropTypes.string.isRequired,
};

WishlistEntryEdit.propTypes = {
  entry: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

export {ShoppinglistEntryDisplay, ShoppinglistEntryEdit, WishlistEntryDisplay, WishlistEntryEdit}