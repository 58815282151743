import React from "react";
import TimeDate from "./TimeDate";
import WeatherToday from "./WeatherToday";
import PropTypes from "prop-types";
import Events from "./Events";


function Home(props) {
  return (
    <React.Fragment>
      <div className="w3-half w3-padding">
        <div className="w3-card w3-margin-bottom">
          <TimeDate/>
        </div>
        <div className="w3-card">
          <WeatherToday globalDispatch={props.globalDispatch}/>
        </div>
      </div>
      <div className="w3-half w3-padding">
        <Events />
      </div>
    </React.Fragment>
  );
}

Home.propTypes = {
  globalDispatch: PropTypes.func.isRequired,
}

export default Home;
